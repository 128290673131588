const styledComponentsTheme = {
  headerHeight: "5.6rem",
  bannerHeight: "3.6rem",
  sidebarWidth: "28rem",
  riskHeight: "33.6rem",
  background1: "#fff",
  background2: "#FAFCFE",
  background3: "#F4F7FC",
  backgroundModal: "rgba(0,0,0,.1)",
  mapBackgroundColor: "#F1F5FB",
  primaryColor: "#E53B46",
  secondaryColor: "#4D73CE",
  secondaryColorFocus: "#6D92EB",
  secondaryColorTint: "#DBE3F5",
  secondaryColorTintRgba: "rgba(77,115,206,.2)",
  secondaryColorTintDark: "#A6B9E6",
  textColor: "#222325",
  textLightColor: "#63656A",
  borderColor: "#BABEC4",
  borderLightColor: "#E6EAF0",
};
export default styledComponentsTheme;
