import React from "react";
import * as Styled from "./styles";

const SidebarDisclaimerStatic = () => (
  <Styled.DisclaimerContainer>
    <strong>Disclaimer</strong>
    <p>
      The number of cases and/or deaths shown are subject to the quality of
      surveillance and reporting, which may vary over time and by country. For
      example, reporting in many countries is low during the weekend, with a
      catch-up on Mondays. The accuracy of the projected cases or deaths vary
      with the data quality and depend on assumptions of our model.{" "}
      <Styled.Link activeClassName="is-active" exact to="/methods">
        {" "}
        Learn more.
      </Styled.Link>
    </p>
  </Styled.DisclaimerContainer>
);

export default SidebarDisclaimerStatic;
