import * as types from "../constants/ActionTypes";
// Both of these functions are to ensure that the Redux DevTools doesn't lag when handling large action payload and state serialization.
// Without these, Redux DevTools will try to serialize huge payload/state changes and this causes lag.

// Sanitizes the state that is displayed in Redux DevTools.
export const sanitizeStateFunction = (state) => {
  if (state.ui) {
    return {
      ...state,
      ui: {
        ...state.ui,
        popoverAnchorElement: state.ui.popoverAnchorElement
          ? "<<POPOVER_ANCHOR_ELEMENT>>"
          : null,
        popperAnchorElement: state.ui.popperAnchorElement
          ? "<<POPPER_ANCHOR_ELEMENT>>"
          : null,
      },
      covid: {
        ...state.covid,
        caseCounts: {
          ...state.covid.caseCounts,
          data: state.covid.caseCounts.data.length
            ? "<<SEE_CASES_DATA_FROM_API>>"
            : [],
        },
        deathCounts: {
          ...state.covid.deathCounts,
          data: state.covid.deathCounts.data.length
            ? "<<SEE_DEATHS_DATA_FROM_API>>"
            : [],
        },
      },
    };
  } else if (state.covid) {
    return {
      ...state,
      covid: {
        ...state.covid,
        caseCounts: {
          ...state.covid.caseCounts,
          data: state.covid.caseCounts.data.length
            ? "<<SEE_CASES_DATA_FROM_API>>"
            : [],
        },
        deathCounts: {
          ...state.covid.deathCounts,
          data: state.covid.deathCounts.data.length
            ? "<<SEE_DEATHS_DATA_FROM_API>>"
            : [],
        },
      },
    };
  } else {
    return state;
  }
};

// Sanitizes the action payloads that are displayed in Redux DevTools.
export const sanitizeActionFunction = (action) => {
  if (action.type === types.SET_POPOVER_ANCHOR_ELEMENT && action.payload) {
    return { ...action, payload: "<<POPOVER_ANCHOR_ELEMENT>>" };
  } else if (
    action.type === types.SET_POPPER_ANCHOR_ELEMENT &&
    action.payload
  ) {
    return { ...action, payload: "<<POPPER_ANCHOR_ELEMENT>>" };
  } else if (
    action.type === types.FETCH_COVID_CASE_COUNT_DATA_SUCCESS &&
    action.payload
  ) {
    return { ...action, payload: "<<SEE_CASES_DATA_FROM_API>>" };
  } else if (
    action.type === types.FETCH_COVID_DEATH_COUNT_DATA_SUCCESS &&
    action.payload
  ) {
    return { ...action, payload: "<<SEE_DEATHS_DATA_FROM_API>>" };
  } else {
    return action;
  }
};
