export const countriesCoordinates = {
  Andorra: { latitude: 42.546245, longitude: 1.601554, zoomLevel: 35 },
  "United Arab Emirates": {
    latitude: 23.424076,
    longitude: 53.847818,
    zoomLevel: 20,
  },
  Afghanistan: { latitude: 33.93911, longitude: 67.709953, zoomLevel: 10 },
  "Antigua and Barbuda": {
    latitude: 17.060816,
    longitude: -61.796428,
    zoomLevel: 35,
  },
  Albania: { latitude: 41.153332, longitude: 20.168331, zoomLevel: 20 },
  Armenia: { latitude: 40.069099, longitude: 45.038189, zoomLevel: 27 },
  Angola: { latitude: -11.202692, longitude: 17.873887, zoomLevel: 10 },
  Argentina: { latitude: -38.416097, longitude: -63.616672, zoomLevel: 5 },
  Samoa: { latitude: -13.759029, longitude: -172.104629, zoomLevel: 32 },
  Austria: { latitude: 47.516231, longitude: 14.550072, zoomLevel: 15 },
  Australia: { latitude: -25.274398, longitude: 133.775136, zoomLevel: 5 },
  Aruba: { latitude: 12.52111, longitude: -69.968338, zoomLevel: 37 },
  Azerbaijan: { latitude: 40.143105, longitude: 47.576927, zoomLevel: 15 },
  "Bosnia and Herzegovina": {
    latitude: 43.915886,
    longitude: 17.679076,
    zoomLevel: 20,
  },
  Barbados: { latitude: 13.193887, longitude: -59.543198, zoomLevel: 37 },
  Bangladesh: { latitude: 23.684994, longitude: 90.356331, zoomLevel: 16 },
  Belgium: { latitude: 50.503887, longitude: 4.469936, zoomLevel: 22 },
  "Burkina Faso": { latitude: 12.238333, longitude: -1.561593, zoomLevel: 16 },
  Bulgaria: { latitude: 42.733883, longitude: 25.48583, zoomLevel: 18 },
  Bahrain: { latitude: 25.930414, longitude: 50.637772, zoomLevel: 35 },
  Burundi: { latitude: -3.373056, longitude: 29.918886, zoomLevel: 27 },
  Benin: { latitude: 9.30769, longitude: 2.315834, zoomLevel: 16 },
  "Brunei Darussalam": {
    latitude: 4.535277,
    longitude: 114.727669,
    zoomLevel: 32,
  },
  Bolivia: { latitude: -16.290154, longitude: -63.588653, zoomLevel: 10 },
  Brazil: { latitude: -14.235004, longitude: -51.92528, zoomLevel: 5 },
  Bahamas: { latitude: 25.03428, longitude: -77.39628, zoomLevel: 27 },
  Bhutan: { latitude: 27.514162, longitude: 90.433601, zoomLevel: 27 },
  Botswana: { latitude: -22.328474, longitude: 24.684866, zoomLevel: 12 },
  Belarus: { latitude: 53.709807, longitude: 27.953389, zoomLevel: 12 },
  Belize: { latitude: 17.189877, longitude: -88.49765, zoomLevel: 27 },
  Canada: { latitude: 56.130366, longitude: -106.346771, zoomLevel: 4 },
  "Democratic Republic of the Congo": {
    latitude: -4.038333,
    longitude: 21.758664,
    zoomLevel: 8,
  },
  "Central African Republic": {
    latitude: 6.611111,
    longitude: 20.939444,
    zoomLevel: 12,
  },
  Congo: { latitude: -0.228021, longitude: 15.827659, zoomLevel: 12 },
  Switzerland: { latitude: 46.818188, longitude: 8.227512, zoomLevel: 20 },
  "Cote dIvoire": { latitude: 7.539989, longitude: -5.54708, zoomLevel: 13 },
  Chile: { latitude: -35.675147, longitude: -71.542969, zoomLevel: 5 },
  Cameroon: { latitude: 7.369722, longitude: 12.354722, zoomLevel: 12 },
  China: { latitude: 35.86166, longitude: 104.195397, zoomLevel: 4 },
  Colombia: { latitude: 4.570868, longitude: -74.297333, zoomLevel: 8 },
  "Costa Rica": { latitude: 9.748917, longitude: -83.753428, zoomLevel: 18 },
  Cuba: { latitude: 21.521757, longitude: -77.781167, zoomLevel: 14 },
  "Cape Verde": { latitude: 16.002082, longitude: -24.013197, zoomLevel: 35 },
  Cyprus: { latitude: 35.126413, longitude: 33.429859, zoomLevel: 27 },
  Czechia: { latitude: 49.817492, longitude: 15.472962, zoomLevel: 18 },
  Germany: { latitude: 51.165691, longitude: 10.451526, zoomLevel: 10 },
  Djibouti: { latitude: 11.825138, longitude: 42.590275, zoomLevel: 27 },
  Denmark: { latitude: 56.26392, longitude: 9.501785, zoomLevel: 16 },
  Dominica: { latitude: 15.414999, longitude: -61.370976, zoomLevel: 35 },
  "Dominican Republic": {
    latitude: 18.735693,
    longitude: -70.162651,
    zoomLevel: 22,
  },
  Algeria: { latitude: 28.033886, longitude: 1.659626, zoomLevel: 7 },
  Ecuador: { latitude: -1.831239, longitude: -78.183406, zoomLevel: 15 },
  Estonia: { latitude: 58.595272, longitude: 25.013607, zoomLevel: 16 },
  Egypt: { latitude: 26.820553, longitude: 30.802498, zoomLevel: 10 },
  Eritrea: { latitude: 15.179384, longitude: 39.782334, zoomLevel: 14 },
  Eswatini: { latitude: -26.5225, longitude: 31.4659, zoomLevel: 35 },
  Spain: { latitude: 40.463667, longitude: -3.74922, zoomLevel: 12 },
  Ethiopia: { latitude: 9.145, longitude: 40.489673, zoomLevel: 10 },
  Finland: { latitude: 61.92411, longitude: 25.748151, zoomLevel: 8 },
  Fiji: { latitude: -16.578193, longitude: 179.414413, zoomLevel: 27 },
  France: { latitude: 46.227638, longitude: 2.213749, zoomLevel: 9 },
  Gabon: { latitude: -0.803689, longitude: 11.609444, zoomLevel: 15 },
  "United Kingdom": {
    latitude: 55.378051,
    longitude: -3.435973,
    zoomLevel: 8,
  },
  Grenada: { latitude: 12.262776, longitude: -61.604171, zoomLevel: 35 },
  Georgia: { latitude: 42.315407, longitude: 43.356892, zoomLevel: 18 },
  Ghana: { latitude: 7.946527, longitude: -1.023194, zoomLevel: 15 },
  Greenland: { latitude: 71.706936, longitude: -42.604303, zoomLevel: 4 },
  Gambia: { latitude: 13.443182, longitude: -15.310139, zoomLevel: 27 },
  Guinea: { latitude: 9.945587, longitude: -9.696645, zoomLevel: 15 },
  "Equatorial Guinea": {
    latitude: 1.650801,
    longitude: 10.267895,
    zoomLevel: 27,
  },
  Greece: { latitude: 39.074208, longitude: 21.824312, zoomLevel: 14 },
  Guatemala: { latitude: 15.783471, longitude: -90.230759, zoomLevel: 18 },
  "Guinea Bissau": {
    latitude: 11.803749,
    longitude: -15.180413,
    zoomLevel: 22,
  },
  Guyana: { latitude: 4.860416, longitude: -58.93018, zoomLevel: 15 },
  Honduras: { latitude: 15.199999, longitude: -86.241905, zoomLevel: 22 },
  Croatia: { latitude: 45.1, longitude: 15.2, zoomLevel: 16 },
  Haiti: { latitude: 18.971187, longitude: -72.285215, zoomLevel: 20 },
  "Holy See": { latitude: 41.9029, longitude: 12.4534, zoomLevel: 35 },
  Hungary: { latitude: 47.162494, longitude: 19.503304, zoomLevel: 18 },
  Indonesia: { latitude: -0.789275, longitude: 113.921327, zoomLevel: 6 },
  Ireland: { latitude: 53.41291, longitude: -8.24389, zoomLevel: 14 },
  Israel: { latitude: 31.046051, longitude: 34.851612, zoomLevel: 20 },
  India: { latitude: 20.593684, longitude: 78.96288, zoomLevel: 5 },
  Iraq: { latitude: 33.223191, longitude: 43.679291, zoomLevel: 12 },
  Iran: { latitude: 32.427908, longitude: 53.688046, zoomLevel: 8 },
  Iceland: { latitude: 64.963051, longitude: -19.020835, zoomLevel: 12 },
  Italy: { latitude: 41.87194, longitude: 12.56738, zoomLevel: 9 },
  Jamaica: { latitude: 18.109581, longitude: -77.297508, zoomLevel: 27 },
  Jordan: { latitude: 30.585164, longitude: 36.238414, zoomLevel: 18 },
  Japan: { latitude: 36.204824, longitude: 138.252924, zoomLevel: 8 },
  Kenya: { latitude: -0.023559, longitude: 37.906193, zoomLevel: 12 },
  Kyrgyzstan: { latitude: 41.20438, longitude: 74.766098, zoomLevel: 14 },
  Cambodia: { latitude: 12.565679, longitude: 104.990963, zoomLevel: 16 },
  Comoros: { latitude: -11.875001, longitude: 43.872219, zoomLevel: 32 },
  "Saint Kitts and Nevis": {
    latitude: 17.357822,
    longitude: -62.782998,
    zoomLevel: 35,
  },
  "South Korea": { latitude: 35.907757, longitude: 127.766922, zoomLevel: 16 },
  Kuwait: { latitude: 29.31166, longitude: 47.481766, zoomLevel: 27 },
  "Cayman Islands": {
    latitude: 19.513469,
    longitude: -80.566956,
    zoomLevel: 35,
  },
  Kazakhstan: { latitude: 48.019573, longitude: 66.923684, zoomLevel: 6 },
  Laos: { latitude: 19.85627, longitude: 102.495496, zoomLevel: 12 },
  Lebanon: { latitude: 33.854721, longitude: 35.862285, zoomLevel: 27 },
  "Saint Lucia": { latitude: 13.909444, longitude: -60.978893, zoomLevel: 35 },
  Liechtenstein: { latitude: 47.166, longitude: 9.555373, zoomLevel: 35 },
  "Sri Lanka": { latitude: 7.873054, longitude: 80.771797, zoomLevel: 18 },
  Liberia: { latitude: 6.428055, longitude: -9.429499, zoomLevel: 16 },
  Lesotho: { latitude: -29.609988, longitude: 28.233608, zoomLevel: 22 },
  Lithuania: { latitude: 55.169438, longitude: 23.881275, zoomLevel: 16 },
  Luxembourg: { latitude: 49.815273, longitude: 6.129583, zoomLevel: 32 },
  Latvia: { latitude: 56.879635, longitude: 24.603189, zoomLevel: 14 },
  Libya: { latitude: 26.3351, longitude: 17.228331, zoomLevel: 8 },
  Morocco: { latitude: 31.791702, longitude: -7.09262, zoomLevel: 7 },
  Monaco: { latitude: 43.750298, longitude: 7.412841, zoomLevel: 12 },
  Moldova: { latitude: 47.411631, longitude: 28.369885, zoomLevel: 18 },
  Montenegro: { latitude: 42.708678, longitude: 19.37439, zoomLevel: 22 },
  Madagascar: { latitude: -18.766947, longitude: 46.869107, zoomLevel: 9 },
  "Marshall Islands": {
    latitude: 7.131474,
    longitude: 171.184478,
    zoomLevel: 12,
  },
  "North Macedonia": {
    latitude: 41.608635,
    longitude: 21.745275,
    zoomLevel: 27,
  },
  Mali: { latitude: 17.570692, longitude: -3.996166, zoomLevel: 8 },
  Myanmar: { latitude: 21.913965, longitude: 95.956223, zoomLevel: 9 },
  Mongolia: { latitude: 46.862496, longitude: 103.846656, zoomLevel: 7 },
  Mauritania: { latitude: 21.00789, longitude: -10.940835, zoomLevel: 9 },
  Malta: { latitude: 35.937496, longitude: 14.375416, zoomLevel: 35 },
  Mauritius: { latitude: -20.348404, longitude: 57.552152, zoomLevel: 27 },
  Maldives: { latitude: 3.202778, longitude: 73.22068, zoomLevel: 20 },
  Malawi: { latitude: -13.254308, longitude: 34.301525, zoomLevel: 14 },
  Mexico: { latitude: 23.634501, longitude: -102.552784, zoomLevel: 8 },
  Malaysia: { latitude: 4.210484, longitude: 101.975766, zoomLevel: 12 },
  Mozambique: { latitude: -18.665695, longitude: 35.529562, zoomLevel: 8 },
  Namibia: { latitude: -22.95764, longitude: 18.49041, zoomLevel: 8 },
  Niger: { latitude: 17.607789, longitude: 8.081666, zoomLevel: 9 },
  Nigeria: { latitude: 9.081999, longitude: 8.675277, zoomLevel: 9 },
  Nicaragua: { latitude: 12.865416, longitude: -85.207229, zoomLevel: 18 },
  Netherlands: { latitude: 52.132633, longitude: 5.291266, zoomLevel: 22 },
  Norway: { latitude: 60.472024, longitude: 8.468946, zoomLevel: 6 },
  Nepal: { latitude: 28.394857, longitude: 84.124008, zoomLevel: 16 },
  "New Zealand": { latitude: -40.900557, longitude: 174.885971, zoomLevel: 9 },
  Oman: { latitude: 21.512583, longitude: 55.923255, zoomLevel: 12 },
  Panama: { latitude: 8.537981, longitude: -80.782127, zoomLevel: 20 },
  Peru: { latitude: -9.189967, longitude: -75.015152, zoomLevel: 8 },
  "Papua New Guinea": {
    latitude: -6.314993,
    longitude: 143.95555,
    zoomLevel: 12,
  },
  Philippines: { latitude: 12.879721, longitude: 121.774017, zoomLevel: 12 },
  Pakistan: { latitude: 30.375321, longitude: 69.345116, zoomLevel: 8 },
  Poland: { latitude: 51.919438, longitude: 19.145136, zoomLevel: 12 },
  "Puerto Rico": { latitude: 18.220833, longitude: -66.590149, zoomLevel: 27 },
  Portugal: { latitude: 39.399872, longitude: -8.224454, zoomLevel: 14 },
  Paraguay: { latitude: -23.442503, longitude: -58.443832, zoomLevel: 12 },
  Qatar: { latitude: 25.354826, longitude: 51.183884, zoomLevel: 27 },
  Romania: { latitude: 45.943161, longitude: 24.96676, zoomLevel: 12 },
  Serbia: { latitude: 44.016521, longitude: 21.005859, zoomLevel: 18 },
  Russia: { latitude: 61.52401, longitude: 105.318756, zoomLevel: 3 },
  Rwanda: { latitude: -1.940278, longitude: 29.873888, zoomLevel: 32 },
  "Saudi Arabia": { latitude: 23.885942, longitude: 45.079162, zoomLevel: 8 },
  "Solomon Islands": {
    latitude: -9.64571,
    longitude: 160.156194,
    zoomLevel: 18,
  },
  Seychelles: { latitude: -4.679574, longitude: 55.491977, zoomLevel: 35 },
  Sudan: { latitude: 12.862807, longitude: 30.217636, zoomLevel: 7 },
  "South Sudan": { latitude: 6.8770, longitude: 31.3070, zoomLevel: 12 },
  Sweden: { latitude: 60.128161, longitude: 18.643501, zoomLevel: 5 },
  Singapore: { latitude: 1.352083, longitude: 103.819836, zoomLevel: 35 },
  Slovenia: { latitude: 46.151241, longitude: 14.995463, zoomLevel: 27 },
  Slovakia: { latitude: 48.669026, longitude: 19.699024, zoomLevel: 22 },
  "Sierra Leone": { latitude: 8.460555, longitude: -11.779889, zoomLevel: 22 },
  "San Marino": { latitude: 43.94236, longitude: 12.457777, zoomLevel: 35 },
  Senegal: { latitude: 14.497401, longitude: -14.452362, zoomLevel: 16 },
  Somalia: { latitude: 5.152149, longitude: 46.199616, zoomLevel:9 },
  Suriname: { latitude: 3.919305, longitude: -56.027783, zoomLevel: 18 },
  "Sao Tome and Principe": {
    latitude: 0.18636,
    longitude: 6.613081,
    zoomLevel: 35,
  },
  "El Salvador": { latitude: 13.794185, longitude: -88.89653, zoomLevel: 27 },
  Syria: { latitude: 34.802075, longitude: 38.996815, zoomLevel: 14 },
  Chad: { latitude: 15.454166, longitude: 18.732207, zoomLevel: 8 },
  Togo: { latitude: 8.619543, longitude: 0.824782, zoomLevel: 16 },
  Thailand: { latitude: 15.870032, longitude: 100.992541, zoomLevel: 9 },
  Tajikistan: { latitude: 38.861034, longitude: 71.276093, zoomLevel: 18 },
  "Timor Leste": { latitude: -8.874217, longitude: 125.727539, zoomLevel: 27 },
  Tunisia: { latitude: 33.886917, longitude: 9.537499, zoomLevel: 12 },
  Turkey: { latitude: 38.963745, longitude: 35.243322, zoomLevel: 12 },
  "Trinidad and Tobago": {
    latitude: 10.691803,
    longitude: -61.222503,
    zoomLevel: 32,
  },
  Taiwan: { latitude: 23.69781, longitude: 120.960515, zoomLevel: 20 },
  "United Republic of Tanzania": {
    latitude: -6.369028,
    longitude: 34.888822,
    zoomLevel: 10,
  },
  Ukraine: { latitude: 48.379433, longitude: 31.16558, zoomLevel: 10 },
  Uganda: { latitude: 1.373333, longitude: 32.290275, zoomLevel: 16 },
  "United States of America": {
    latitude: 37.09024,
    longitude: -95.712891,
    zoomLevel: 5,
  },
  Uruguay: { latitude: -32.522779, longitude: -55.765835, zoomLevel: 14 },
  Uzbekistan: { latitude: 41.377491, longitude: 64.585262, zoomLevel: 10 },
  "Saint Vincent and the Grenadines": {
    latitude: 12.984305,
    longitude: -61.287228,
    zoomLevel: 35,
  },
  Venezuela: { latitude: 6.42375, longitude: -66.58973, zoomLevel: 10 },
  "British Virgin Islands": {
    latitude: 18.420695,
    longitude: -64.639968,
    zoomLevel: 35,
  },
  Vietnam: { latitude: 14.058324, longitude: 108.277199, zoomLevel: 8 },
  Vanuatu: { latitude: -15.376706, longitude: 166.959158, zoomLevel: 16 },
  "Wallis and Futuna": {
    latitude: -13.768752,
    longitude: -177.156097,
    zoomLevel: 12,
  },
  Kosovo: { latitude: 42.602636, longitude: 20.902977, zoomLevel: 27 },
  Yemen: { latitude: 15.552727, longitude: 48.516388, zoomLevel: 12 },
  "South Africa": { latitude: -30.559482, longitude: 22.937506, zoomLevel: 8 },
  Zambia: { latitude: -13.133897, longitude: 27.849332, zoomLevel: 12 },
  Zimbabwe: { latitude: -19.015438, longitude: 29.154857, zoomLevel: 12 },
};
