import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Popper from "@material-ui/core/Popper";

export const StyledPopper = styled(Popper)`
  z-index: 4;
  background-color: ${(props) => props.theme.background1};
  box-shadow: 0 20px 48px 0 rgba(0, 0, 0, 0.2);
  margin: 0 0 3.2rem 1.6rem;
  border-radius: 3px;
  Button {
    text-transform: none;
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    padding: 0;
    min-width: 0;
    img {
      height: 1.6rem;

      width: 1.6rem;
    }
    &:focus,
    &:hover {
      background-color: transparent;
    }
  }
`;

export const Title = styled.div`
  font-size: 2rem;
  line-height: 1;
  font-weight: 600;
`;

export const DisclaimerContainer = styled.div`
  max-width: 48rem;
  padding: 3.2rem;
  border-radius: 3px;
  p {
    font-size: 1.6rem;
    line-height: 1.5;
    margin: 1.6rem 0 0 0;
    padding: 0;
  }
`;

export const DisclaimerTopElements = styled.div`
  max-width: 450px;
`;

export const Link = styled(NavLink)`
  color: ${(props) => props.theme.textColor};
`;
