import * as types from "../constants/ActionTypes";

const initialState = {
  country: "All",
  outbreak: "COVID-19",
  dataType: "cases",
  dateRange: { from: new Date(2014, 9, 1), to: new Date(2016, 1, 17) },
  firstDataLoad: true,
  epidemicPhaseContinent: "Europe",
};

const Filters = function (state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    case types.CHANGE_OUTBREAK:
      return {
        ...state,
        outbreak: action.payload,
      };
    case types.CHANGE_DATE_RANGE:
      return {
        ...state,
        dateRange: {
          from: action.payload[0],
          to: action.payload[1],
        },
      };
    case types.CHANGE_DATA_TYPE:
      return {
        ...state,
        dataType: action.payload,
      };
    case types.CHANGE_FIRST_DATA_LOAD:
      return {
        ...state,
        firstDataLoad: action.payload,
      };
    case types.CHANGE_EPIDEMIC_PHASE_CONTINENT:
      return {
        ...state,
        epidemicPhaseContinent: action.payload,
      };
    default:
      return state;
  }
};

export default Filters;
