import styled from "styled-components";

export const FirstPageContainer = styled.div`
  margin-top: ${(props) => props.theme.headerHeight};
`;

export const SecondPageContainer = styled.div`
  background: ${(props) => props.theme.background3};
`;

export const TeamMemberWrapper = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  margin: 0 -1.6rem;
`;
