import React from "react";
import { renderToString } from "react-dom/server";
import Popup from "./Popup";

export const options = {
  chart: {
    id: "reproduction-number-chart",
    type: "boxPlot",
    height: "100%",
    width: "100%",
  },
  series: [],
  noData: {
    text: "No data currently available.",
  },
  xaxis: {
    title: {
      text: "Effective Reproduction Number",
    },
    min: 0,
    labels: {
      formatter: (val) => {
        return val.toFixed(0);
      },
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: "50%",
    },
    boxPlot: {
      colors: {
        upper: "#F1CAEF",
        lower: "#F1CAEF",
      },
    },
  },
  stroke: {
    colors: ["#6c757d"],
  },
  annotations: {
    position: "back",
    xaxis: [{ x: 1 }],
  },
  tooltip: {
    intersect: true,
    shared: false,
    custom: ({ seriesIndex, dataPointIndex, w }) => {
      const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
      return renderToString(<Popup data={data} />);
    },
  },
};
