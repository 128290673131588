import React from "react";
import { Alert } from "@material-ui/lab";

interface Props {
  text: string;
}

export const InfoBanner: React.FC<Props> = ({ text }) => (
  <Alert severity="info" style={{ fontSize: "1.3rem" }}>
    <strong>{text}</strong>
  </Alert>
);
