import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import dayjs from "dayjs";
import {
  changeCountryFilter,
  changeOutbreakFilter,
  changeDataType,
  changeFirstDataLoad,
} from "../../actions/filters";
import {
  openDateRangePopover,
  setPopoverAnchorElement,
  setPopperAnchorElement,
} from "../../actions/ui";
import OutbreakSelect from "./OutbreakSelect";
import SidebarCount from "./SidebarCount";
import SidebarDisclaimer from "./SidebarDisclaimer";
import SidebarDisclaimerStatic from "./SidebarDisclaimerStatic";
import EbolaRiskList from "../EbolaRiskMap/EbolaRiskList";
import { StyledInputLabel } from "../SharedStyledComponents";
import CountrySelect from "./CountrySelect";
import DataRadioButtons from "./DataRadioButtons";
import * as Styled from "./styles";

const Sidebar = ({
  filters,
  popperAnchorElement,
  changeCountryFilter,
  changeOutbreakFilter,
  openDateRangePopover,
  setPopoverAnchorElement,
  setPopperAnchorElement,
  changeDataType,
  dataType,
  changeFirstDataLoad,
}) => {
  // This is used to set a ref for the parent div that houses the button to open the date range popover.
  // We need this ref to tell the DateRangePopover to use this element as it's anchor.
  const popoverButtonDivRef = useRef();
  // This is used to set a ref for the parent div that houses the SidebarDisclaimer.
  const disclaimerPopperDivRef = useRef();
  // Set the popperAnchorElement in the UI Redux state when the Sidebar is first rendered.
  useEffect(() => setPopperAnchorElement(disclaimerPopperDivRef.current), [
    setPopperAnchorElement,
  ]);

  const changeOutbreak = (selectedValue) => {
    if (selectedValue.target.value) {
      changeOutbreakFilter(selectedValue.target.value);
      // This resets the country filter to 'All' whenever you switch between outbreaks
      changeCountryFilter("All");
      // Reset the data type to "cases"
      changeDataType("cases");
      //Reset FirstDataLoad to true
      changeFirstDataLoad(true);
    }
  };

  const handleDateRangePopoverOpen = () => {
    // Set the anchor element for the date range popover.
    setPopoverAnchorElement(popoverButtonDivRef.current);
    // Open the date range popover.
    openDateRangePopover();
  };

  const showSidebarCount =
    filters.dataType !== "risk" && dataType !== "projected deaths";
  const showEbolaRiskList =
    filters.dataType === "risk" && filters.outbreak === "Ebola Outbreak";
  const showDateRangeSelect = dataType !== "projected deaths";
  return (
    <>
      <Styled.DisclaimerWrapper>
        <div id="disclaimer-parent" ref={disclaimerPopperDivRef}>
          {disclaimerPopperDivRef.current && (
            <SidebarDisclaimer anchorElement={popperAnchorElement} />
          )}
        </div>
      </Styled.DisclaimerWrapper>
      <Styled.SidebarWrapper>
        <Styled.SelectCountryWrapper>
          <CountrySelect />
        </Styled.SelectCountryWrapper>
        <Styled.SelectOutbreakWrapper>
          <OutbreakSelect
            name="outbreak"
            type="outbreak"
            optionsCurrent={["COVID-19"]}
            optionsPast={["Ebola Outbreak"]}
            value={filters.outbreak}
            changeFunction={changeOutbreak}
          />
        </Styled.SelectOutbreakWrapper>
        {showDateRangeSelect && (
          <>
            {" "}
            <StyledInputLabel>Timespan</StyledInputLabel>
            <div
              data-test-id="open-date-range-button"
              ref={popoverButtonDivRef}
            >
              <Styled.Button onClick={() => handleDateRangePopoverOpen()}>
                {dayjs(filters.dateRange.from).format("MMM D, YYYY")} -{" "}
                {dayjs(filters.dateRange.to).format("MMM D, YYYY")}
              </Styled.Button>
            </div>
          </>
        )}
        <DataRadioButtons />
        {showSidebarCount && <SidebarCount />}
        {showEbolaRiskList && <EbolaRiskList />}
        <SidebarDisclaimerStatic />
      </Styled.SidebarWrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  filters: state.filters,
  popperAnchorElement: state.ui.popperAnchorElement,
  dataType: state.filters.dataType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeCountryFilter,
      changeOutbreakFilter,
      openDateRangePopover,
      setPopoverAnchorElement,
      setPopperAnchorElement,
      changeDataType,
      changeFirstDataLoad,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
