import React from "react";
import MapLegendLevel from "../../SharedComponents/MapLegendLevel";
import {
  StyledBlockDropshadow,
  MapLegendWrapper,
} from "../../SharedStyledComponents";

const riskColors = ["#3DB0E2", "#97D0E8", "#B3DAEA", "#DAE7ED", "#E2EAED"];
const riskLabels = ["High", "", "Med", "", "Low"];

const RiskMapLegend = () => (
  <MapLegendWrapper>
    <StyledBlockDropshadow>
      <h3>Projected relative risk of spread</h3>
      {riskColors.map((color, index) => (
        <MapLegendLevel
          key={`risk-legend-level-${index}`}
          color={color}
          value={riskLabels[index]}
        />
      ))}
    </StyledBlockDropshadow>
  </MapLegendWrapper>
);

export default RiskMapLegend;
