import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { media } from "../../assets/style-utils";

export const StyledBlockPadded = styled.div`
  padding: 1.2rem 1.2rem 3.2rem;
  font-size: 1.6rem;
  line-height: 1.5;
  border-radius: 3px;
  background-color: ${(props) => props.theme.background1};
  border: 1px solid ${(props) => props.theme.borderColor};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 3.2rem;
  p {
    margin: 0;
  }
  ol {
    font-size: 1.4rem;
    line-height: 1.42857142;
    margin: 1.6rem 0 0 0;
    padding: 0;
    list-style-position: inside;
    li {
      margin: 0 0 0.4rem 0;
    }
  }
`;

export const StyledBlockDropshadow = styled.div`
  display: inline-block;
  padding: 1.2rem;
  border-radius: 3px;
  background-color: #fff;
`;

export const StyledInputLabel = styled.label`
  margin: 2.4rem 1.2rem 0.4rem;
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  display: block;
  font-weight: 700;
`;

export const ChartTitle = styled.h2`
  position: relative;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.2;
  margin-top: 3.2rem;
`;

export const MapContainer = styled.div`
  flex: none;
  position: relative;
  margin-top: ${(props) => props.theme.headerHeight};
  padding-left: ${(props) => props.theme.sidebarWidth};
  height: calc(50vh - ${(props) => props.theme.headerHeight});
  min-height: 40rem;
  overflow: hidden;
`;

export const StyledTooltip = styled(ReactTooltip)`
  && {
    color: ${(props) => props.theme.textColor};
    background-color: ${(props) => props.theme.background1};
    opacity: 0.95 !important;
    border-radius: 3px;
  }
  &:after {
    border-top-color: ${(props) => props.theme.background1} !important;
  }
  &.place-bottom:after {
    border-bottom-color: ${(props) => props.theme.background1} !important;
  }
`;

export const PageWrapper = styled.div`
  margin-top: ${(props) => props.theme.headerHeight};
`;

export const ContentWrapper = styled.div`
  max-width: 1400px;
  margin: 6.4rem auto;
  padding: 0 3.2rem;
`;

export const SectionWrapperLight = styled.div`
  background: ${(props) => props.theme.background3};
  overflow: auto;
`;

export const Title = styled.h2`
  font-size: 4.8rem;
  font-weight: 700;
  margin: 0 0 3.2rem;
  text-align: center;
`;

export const SectionTitle = styled.h2`
  font-size: 3.2rem;
  line-height: 1.5;
  font-weight: 700;
  margin: 0 auto 3.2rem;
  ${media.mediumScreen`
    max-width: calc(8 / 12 * 100%);
  `}
  &:after {
    content: "";
    display: block;
    width: 10rem;
    padding-top: 0.4rem;
    border-bottom: 0.4rem solid ${(props) => props.theme.secondaryColor};
  }
`;

export const ExternalLink = styled.a`
  font-weight: 700;
  color: ${(props) => props.theme.textColor};
  text-decoration: none;
  display: flex;
  margin-bottom: -2.4rem;
  img {
    margin-left: 0.8rem;
  }
`;

export const BodyLarge = styled.p`
  font-size: 1.8rem;
  line-height: 1.7777777;
  margin: 0 auto;
  ${media.smallScreen`
  font-size: 2.4rem;
  line-height: 1.6666666666666666;
  `}

  ${media.mediumScreen`
    max-width: calc(8 / 12 * 100%);
  `}
`;

export const MapLegendWrapper = styled.div`
  position: absolute;
  padding-left: ${(props) => props.theme.sidebarWidth};
  left: 2rem;
  bottom: 3.2rem;
`;
