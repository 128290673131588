import React, { useEffect, useState, memo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import { changeCountryFilter } from "../../actions/filters";
import MapLegend from "./MapLegend";
import Popup from "./Popup";
import { countriesCoordinates } from "../../constants/CountriesCoordinates";
import { defaultCoordinates } from "./constants";
import { getFillColor } from "./helpers";
import { MapContainer, StyledTooltip } from "../SharedStyledComponents";
import { InfoBanner } from "../InfoBanner";
import { covidInfoBannerText } from "../../constants/CommonText";

const ProjectedDeathsMap = ({
  countryEpidemicPhaseData,
  changeCountryFilter,
  filters,
}) => {
  const [mapCenter, setMapCenter] = useState(defaultCoordinates.mapCenter);
  const [zoomLevel, setZoomLevel] = useState(defaultCoordinates.zoomLevel);
  const [toolTipContent, setToolTipContent] = useState(null);

  const resetMapCenterAndZoom = () => {
    setMapCenter(defaultCoordinates.mapCenter);
    setZoomLevel(defaultCoordinates.zoomLevel);
  };

  useEffect(() => {
    const selectedCountryCoordinates = countriesCoordinates[filters.country];
    if (filters.country !== "All" && selectedCountryCoordinates) {
      setMapCenter([
        selectedCountryCoordinates.longitude,
        selectedCountryCoordinates.latitude,
      ]);
      setZoomLevel(selectedCountryCoordinates.zoomLevel);
    } else {
      resetMapCenterAndZoom();
    }
  }, [filters.country]);

  return (
    <MapContainer>
      <InfoBanner text={covidInfoBannerText} />
      <StyledTooltip>{toolTipContent}</StyledTooltip>
      <ComposableMap
        projection="geoMercator"
        height={700}
        style={{ backgroundColor: "#F1F5FB", width: "100%", height: "100%" }}
        stroke="#131D34"
        strokeWidth={0.02}
        data-tip=""
      >
        <ZoomableGroup zoom={zoomLevel} center={mapCenter} maxZoom={35}>
          <Geographies geography="mapData/world_50m.json">
            {({ geographies }) =>
              geographies.map((geo) => {
                const countryData =
                  countryEpidemicPhaseData[geo.properties.NAME];
                const fillColor = getFillColor(countryData?.phase);
                return (
                  <Geography
                    key={geo.rsmKey}
                    data-test-id={geo.rsmKey}
                    geography={geo}
                    onClick={() => {
                      changeCountryFilter(geo.properties.NAME);
                    }}
                    onMouseEnter={() => {
                      setToolTipContent(
                        <Popup countryName={geo.properties.NAME} />
                      );
                    }}
                    onMouseLeave={() => setToolTipContent(null)}
                    style={{
                      default: {
                        fill: fillColor,
                        opacity: 1,
                        transition: "all .5s ease",
                      },
                      hover: {
                        fill: fillColor,
                        opacity: 0.8,
                        transition: "all .5s ease",
                      },
                      pressed: {
                        fill: fillColor,
                        opacity: 0.8,
                        transition: "all .5s ease",
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <MapLegend />
    </MapContainer>
  );
};

const mapStateToProps = (state) => ({
  filters: state.filters,
  countryEpidemicPhaseData: state.covid.countryEpidemicPhase.data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeCountryFilter,
    },
    dispatch
  );

// Wrapped SnapshotMap component in memo() as recommended here: https://www.react-simple-maps.io/examples/map-chart-with-tooltip/
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(ProjectedDeathsMap));
