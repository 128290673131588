import { findCountryDataObject } from "../../../utils/covidDataHelpers";
import { capitalizeString } from "../../../utils/commonHelpers";
import {
  EpidemicPhaseData,
  CountryCovidCounts,
  WeeklyProjectionsData,
} from "../../../common/types";

export const getReproductionNumber = (countryData: EpidemicPhaseData) =>
  countryData
    ? `${countryData["50%"]} (${countryData["2.5%"]} - ${countryData["97.5%"]})`
    : "N/A";

export const getPhaseLabelText = (phase?: string) => {
  if (!phase) {
    return "Unknown";
  } else if (phase === "stable/growing slowly") {
    return "Stable/Growing Slowly";
  } else {
    return capitalizeString(phase);
  }
};

export const getForecastDateKeyIndex = (
  dateKeys: string[] = [],
  forecastWeek: string
) => dateKeys.findIndex((dateKey) => dateKey === forecastWeek);

export const getObservedDeathsLastWeek = (
  countryName: string,
  forecastWeek: string,
  covidDeathCountsData: CountryCovidCounts[]
) => {
  const countryDeathCountsData = findCountryDataObject(
    covidDeathCountsData,
    countryName
  ) as CountryCovidCounts;
  let deathCount = 0;
  if (countryDeathCountsData) {
    const dateKeys = Object.keys(countryDeathCountsData.countryData);
    const forecastDateKeyIndex = getForecastDateKeyIndex(
      dateKeys,
      forecastWeek
    );
    const lastWeekDateKeys = dateKeys.slice(
      forecastDateKeyIndex - 7,
      forecastDateKeyIndex
    );
    lastWeekDateKeys.forEach((dateKey) => {
      deathCount += countryDeathCountsData.countryData[dateKey].totalCount;
    });
  }
  return deathCount.toLocaleString();
};

export const getCurrentWeekPredictedDeaths = (
  countryProjections?: WeeklyProjectionsData
) =>
  countryProjections
    ? `${countryProjections[50].toLocaleString()} (${countryProjections[2.5].toLocaleString()} - ${countryProjections[97.5].toLocaleString()})`
    : "N/A";
