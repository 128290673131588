import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as StyledComponentsProvider } from "styled-components";
import styledComponentsTheme from "./assets/sc-theme";
import theme from "./assets/theme";
import { fetchEbolaData, fetchEbolaDataCombined } from "./actions/ebola";
import {
  fetchCovidCaseCounts,
  fetchCovidDeathCounts,
  fetchCovidDailyProjectionsData,
  fetchCovidWeeklyProjectionsData,
  fetchCountryEpidemicPhaseData,
} from "./actions/covid";
import SnapshotMap from "./components/SnapshotMap";
import EbolaRiskMap from "./components/EbolaRiskMap";
import Team from "./components/Team";
import About from "./components/About";
import Methods from "./components/Methods";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import ChartComponent from "./components/ChartComponent";
import ProjectedDeathsMap from "./components/ProjectedDeathsMap";
import CovidProjectionsChart from "./components/CovidProjectionsChart";
import DateRangePopover from "./components/DateRangePopover";
import DateRange from "./components/DateRange";
import * as Styled from "./styles";
import { Loader } from "./components/Loader";

class App extends Component {
  componentDidMount() {
    this.props.fetchEbolaData();
    this.props.fetchEbolaDataCombined();
    this.props.fetchCovidCaseCounts();
    this.props.fetchCovidDeathCounts();
    this.props.fetchCovidDailyProjectionsData();
    this.props.fetchCovidWeeklyProjectionsData();
    this.props.fetchCountryEpidemicPhaseData();
  }

  renderDataComponents = () => {
    if (
      this.props.filters.dataType === "risk" &&
      this.props.filters.outbreak === "Ebola Outbreak"
    ) {
      return <EbolaRiskMap />;
    } else if (
      this.props.filters.dataType === "projected deaths" &&
      this.props.filters.outbreak === "COVID-19"
    ) {
      return (
        <>
          <ProjectedDeathsMap />
          <CovidProjectionsChart />
        </>
      );
    } else {
      return (
        <>
          <SnapshotMap />
          {this.props.filters.outbreak === "COVID-19" &&
            (this.props.covidCaseCountsDataIsFetching ||
              this.props.covidDeathCountsDataIsFetching ||
              this.props.projectedDailyDeathsIsFetching) && <Loader />}
          <ChartComponent />
          <DateRange />
        </>
      );
    }
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <StyledComponentsProvider theme={styledComponentsTheme}>
          <Router>
            <Styled.AppContainer>
              <Header />
              <Switch>
                <Route exact path="/">
                  <Sidebar />
                  <DateRangePopover />
                  {this.renderDataComponents()}
                </Route>
                <Route exact path="/about">
                  <About />
                </Route>
                <Route exact path="/methods">
                  <Methods />
                </Route>
                <Route exact path="/team">
                  <Team />
                </Route>
              </Switch>
            </Styled.AppContainer>
          </Router>
        </StyledComponentsProvider>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.filters,
  covidCaseCountsDataIsFetching: state.covid.caseCounts.isFetching,
  covidDeathCountsDataIsFetching: state.covid.deathCounts.isFetching,
  projectedDailyDeathsIsFetching: state.covid.projections.daily.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEbolaData: () => dispatch(fetchEbolaData()),
  fetchEbolaDataCombined: () => dispatch(fetchEbolaDataCombined()),
  fetchCovidCaseCounts: () => dispatch(fetchCovidCaseCounts()),
  fetchCovidDeathCounts: () => dispatch(fetchCovidDeathCounts()),
  fetchCovidDailyProjectionsData: () =>
    dispatch(fetchCovidDailyProjectionsData()),
  fetchCovidWeeklyProjectionsData: () =>
    dispatch(fetchCovidWeeklyProjectionsData()),
  fetchCountryEpidemicPhaseData: () =>
    dispatch(fetchCountryEpidemicPhaseData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
