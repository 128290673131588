import React from "react";
import LogoGroup from "../SharedComponents/LogoGroup";
import * as Styled from "../SharedStyledComponents";
import { Body } from "./styles";

const Methods = () => (
  <Styled.PageWrapper>
    <Styled.ContentWrapper>
      <Styled.Title>Methods</Styled.Title>
    </Styled.ContentWrapper>
    <Styled.SectionWrapperLight>
      <Styled.ContentWrapper>
        <Styled.SectionTitle>COVID-19</Styled.SectionTitle>
        <Body>
          We produce short-term forecasts of deaths due to COVID-19 as reported
          deaths are likely more reliable and stable over time than reported
          cases. In countries with poor reporting of deaths, these forecasts
          will likely represent an under-estimate while the forecasts for
          countries with a small number of reported deaths might be unreliable.
        </Body>
        <Body>
          Note that the results presented here do not explicitly model the
          various interventions and control efforts put in place by countries.
          Our estimates of transmissibility reflect the epidemiological
          situation at the time of the infection of COVID-19 fatalities.
          Therefore, the impact of controls on estimated transmissibility will
          be quantifiable with a delay between transmission and death. The
          forecasts are made from an unweighted ensemble of three different
          models. The individual models are described below.
        </Body>
        <Body>
          We define the 'epidemic phase’ in a country using the estimated
          reproduction number R and associated uncertainty. Briefly, the phase
          is defined as declining if R is below 1 and the uncertainty is small.
          Similarly, we define the phase to be 'growing’ if R is above 1 with
          small uncertainty in estimates and 'stable/growing slowly’ if R is
          close to 1 with small uncertainty. In some instances, we might not
          have enough data to estimate R precisely. In this case, we define the
          phase as 'uncertain’.
        </Body>
        <Body>
          <Styled.ExternalLink href="https://mrc-ide.github.io/covid19-short-term-forecasts/#model-1">
            See a detailed description of the individual models{" "}
            <img alt="External Link" src="icons/arrow-circle-right.svg" />
          </Styled.ExternalLink>
        </Body>
      </Styled.ContentWrapper>
    </Styled.SectionWrapperLight>
    <Styled.ContentWrapper>
      <Styled.SectionTitle>Ebola</Styled.SectionTitle>
      <Body>
        We used a spatially explicit branching process model to project cases
        over a 2 week horizon. The model uses a renewal equation coupled with a
        gravity model of human population movement for between country movement.
      </Body>
      <Body>
        <Styled.ExternalLink href="https://www.nature.com/articles/s41746-021-00442-3">
          See a detailed description of the methods{" "}
          <img alt="External Link" src="icons/arrow-circle-right.svg" />
        </Styled.ExternalLink>
      </Body>
    </Styled.ContentWrapper>
    <LogoGroup />
  </Styled.PageWrapper>
);

export default Methods;
