import styled from "styled-components";

export const ChartContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: ${(props) => props.theme.sidebarWidth};
  height: calc(50vh - 10rem);
  min-height: 32rem;
  margin: 0 2rem;
`;

export const DataSource = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  a {
    color: inherit;
  }
`;
