export const getFillColor = (phase?: string): string => {
  switch (phase) {
    case "growing":
      return "#AB759B";
    case "stable/growing slowly":
      return "#AAD4CF";
    case "decline":
      return "#4DBDB3";
    case "unclear":
      return "#EBD1C1";
    default:
      return "#E7E7E7";
  }
};
