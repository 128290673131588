import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeEpidemicPhaseContinent } from "../../../actions/filters";
import * as continents from "../../../constants/Continents";
import * as Styled from "./styles";

const ContinentButtons = ({ changeEpidemicPhaseContinent }) => {
  return (
    <Styled.Container>
      <strong>PROJECTIONS FOR: </strong>
      <Styled.Button
        autoFocus
        onClick={() => changeEpidemicPhaseContinent(continents.EUROPE)}
      >
        {continents.EUROPE}
      </Styled.Button>
      <Styled.Button
        onClick={() => changeEpidemicPhaseContinent(continents.ASIA)}
      >
        {continents.ASIA}
      </Styled.Button>
      <Styled.Button
        onClick={() => changeEpidemicPhaseContinent(continents.AFRICA)}
      >
        {continents.AFRICA}
      </Styled.Button>
      <Styled.Button
        onClick={() => changeEpidemicPhaseContinent(continents.NORTH_AMERICA)}
      >
        North & Central America
      </Styled.Button>
      <Styled.Button
        onClick={() => changeEpidemicPhaseContinent(continents.OCEANIA)}
      >
        {continents.OCEANIA}
      </Styled.Button>
      <Styled.Button
        onClick={() => changeEpidemicPhaseContinent(continents.SOUTH_AMERICA)}
      >
        {continents.SOUTH_AMERICA}
      </Styled.Button>
    </Styled.Container>
  );
};

const mapStateToProps = (state) => ({
  epidemicPhaseContinent: state.filters.epidemicPhaseContinent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeEpidemicPhaseContinent,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContinentButtons);
