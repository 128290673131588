export type DateRange = { from: Date; to: Date };

export interface Filters {
  country: string;
  outbreak: string;
  dataType: string;
  dateRange: DateRange;
  firstDataLoad: boolean;
  epidemicPhaseContinent: string;
}

export enum CovidCountType {
  TOTAL_COUNT = "totalCount",
  PER_100K_COUNT = "per100kCount",
}

export type CovidCounts = {
  totalCount: number;
  per100kCount: number;
};

export type CovidCountsData = {
  [date: string]: CovidCounts;
};

export interface CountryCovidCounts {
  countryName: string;
  countryData: CovidCountsData;
}

export type CovidProjections = null | {
  2.5: number;
  50: number;
  97.5: number;
};

export type CovidProjectionsData = {
  [date: string]: CovidProjections;
};

export interface CountryCovidProjections {
  countryName: string;
  countryData: CovidProjectionsData;
}

export type WeeklyProjectionsData = {
  projectionsDate: string;
  2.5: number;
  50: number;
  97.5: number;
};

export interface CountriesWeeklyDeathProjections {
  [countryName: string]: WeeklyProjectionsData;
}

export type EpidemicPhaseData = {
  phase: string;
  continent: string;
  "2.5%": number;
  "25%": number;
  "50%": number;
  "75%": number;
  "97.5%": number;
  forecastWeek: string;
};

export interface CountriesEpidemicPhaseData {
  [countryName: string]: EpidemicPhaseData;
}

export type EbolaProjections = {
  [weekKey: string]: {
    [projectionKey: string]: number;
  };
};

export interface CountriesEbolaData {
  [countryName: string]: {
    [date: string]: {
      projections: EbolaProjections;
      value: string;
    };
  };
}

export type CountriesEbolaCounts = {
  [countryName: string]: number;
};

export type EbolaDataCombined = {
  week_starting: string;
  projection_from: string;
  Liberia: string;
  "Sierra Leone": string;
  Guinea: string;
  aggregated: string;
  "y1.aggregated": string;
  "y1.Guinea": string;
  "y1.Liberia": string;
  "y1.Sierra Leone": string;
  "y2.aggregated": string;
  "y2.Guinea": string;
  "y2.Liberia": string;
  "y2.Sierra Leone": string;
  "y3.aggregated": string;
  "y3.Guinea": string;
  "y3.Liberia": string;
  "y3.Sierra Leone": string;
  "y4.aggregated": string;
  "y4.Guinea": string;
  "y4.Liberia": string;
  "y4.Sierra Leone": string;
  "ymin1.aggregated": string;
  "ymin1.Guinea": string;
  "ymin1.Liberia": string;
  "ymin1.Sierra Leone": string;
  "ymin2.aggregated": string;
  "ymin2.Guinea": string;
  "ymin2.Liberia": string;
  "ymin2.Sierra Leone": string;
  "ymin3.aggregated": string;
  "ymin3.Guinea": string;
  "ymin3.Liberia": string;
  "ymin3.Sierra Leone": string;
  "ymin4.aggregated": string;
  "ymin4.Guinea": string;
  "ymin4.Liberia": string;
  "ymin4.Sierra Leone": string;
  "ymax1.aggregated": string;
  "ymax1.Guinea": string;
  "ymax1.Liberia": string;
  "ymax1.Sierra Leone": string;
  "ymax2.aggregated": string;
  "ymax2.Guinea": string;
  "ymax2.Liberia": string;
  "ymax2.Sierra Leone": string;
  "ymax3.aggregated": string;
  "ymax3.Guinea": string;
  "ymax3.Liberia": string;
  "ymax3.Sierra Leone": string;
  "ymax4.aggregated": string;
  "ymax4.Guinea": string;
  "ymax4.Liberia": string;
  "ymax4.Sierra Leone": string;
}[];
