import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { closeDisclaimerPopper } from "../../../actions/ui";
import Button from "@material-ui/core/Button";
import * as Styled from "./styles";

const SidebarDisclaimer = ({
  isDisclaimerPopperOpen,
  anchorElement,
  closeDisclaimerPopper,
}) => (
  <Styled.StyledPopper
    open={isDisclaimerPopperOpen}
    anchorEl={anchorElement}
    placement="top-end"
  >
    <Styled.DisclaimerContainer data-test-id="disclaimer-popper-content">
      <Button onClick={() => closeDisclaimerPopper()}>
        <img alt="Close" src="icons/close.svg" />
      </Button>
      <Styled.DisclaimerTopElements>
        <Styled.Title>Disclaimer</Styled.Title>
      </Styled.DisclaimerTopElements>
      <p>
        The number of cases and/or deaths shown are subject to the quality of
        surveillance and reporting, which may vary over time and by country. For
        example, reporting in many countries is low during the weekend, with a
        catch-up on Mondays. The accuracy of the projected cases or deaths vary
        with the data quality and depend on assumptions of our model.{" "}
        <Styled.Link activeClassName="is-active" exact to="/methods">
          Learn more.
        </Styled.Link>
      </p>
    </Styled.DisclaimerContainer>
  </Styled.StyledPopper>
);

const mapStateToProps = (state) => ({
  isDisclaimerPopperOpen: state.ui.isDisclaimerPopperOpen,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ closeDisclaimerPopper }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SidebarDisclaimer);
