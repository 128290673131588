import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "./Logo";
import * as Styled from "./styles";

const Header = () => (
  <Styled.HeaderContainer>
    <Styled.HeaderWrapper>
      <Logo />
      <Styled.HeaderNavWrapper>
        <li>
          <NavLink activeClassName="is-active" exact to="/">
            Outbreaks
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="is-active" exact to="/about">
            About
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="is-active" exact to="/methods">
            Methods
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="is-active" exact to="/team">
            Team
          </NavLink>
        </li>
      </Styled.HeaderNavWrapper>
    </Styled.HeaderWrapper>
  </Styled.HeaderContainer>
);

export default Header;
