import dayjs from "dayjs";
import { isDateWithinFiltersDateRange } from "./dateHelpers";
import { findCountryDataObject } from "./covidDataHelpers";
import { capitalizeString } from "./commonHelpers";
import {
  EbolaProjections,
  EbolaDataCombined,
  CountriesEbolaData,
  CountryCovidCounts,
  CountryCovidProjections,
  CovidProjectionsData,
  CountriesEpidemicPhaseData,
  Filters,
} from "../common/types";

export const getChartTitle = (
  outbreakSelected: string,
  dataType: string,
  countrySelected: string
) => {
  const timeText = outbreakSelected === "COVID-19" ? "Daily" : "Weekly";
  const dataTypeText =
    dataType === "projected cases"
      ? "Projected Cases"
      : capitalizeString(dataType);
  const locationText =
    countrySelected === "All" ? "all Locations" : countrySelected;
  return `${timeText} ${dataTypeText} in ${locationText}`;
};

export const getDataColumnLabel = (outbreakName: string, dataType: string) => {
  // If the dataType is either "cases" or "deaths", return the outbreak name with either "Cases" or "Deaths".
  if (dataType === "cases" || dataType === "deaths") {
    return `${outbreakName} ${capitalizeString(dataType)}`;
  } else {
    // Otherwise return the outbreak name with the second word in the dataTypeWordArray (either "Cases" or "Deaths").
    const dataTypeWordArray = dataType.split(" ");
    return `${outbreakName} ${capitalizeString(dataTypeWordArray[1])}`;
  }
};

type Column = { type: string; label: string };
type IntervalColumn = { id: string; type: string; role: string };
type ChartColumns = (Column | IntervalColumn)[];

export const getChartColumns = (
  outbreakName: string,
  dataType: string = "cases"
): ChartColumns => {
  const columns: ChartColumns = [
    {
      type: "date",
      label: "Date",
    },
    {
      type: "number",
      label: getDataColumnLabel(outbreakName, dataType),
    },
  ];
  // If the dataType is equal to "projected cases", add projections columns to columns array.
  if (dataType === "projected cases") {
    columns.push(
      {
        type: "number",
        label: "Projected Future Cases",
      },
      { id: "interval-1", type: "number", role: "interval" },
      { id: "interval-2", type: "number", role: "interval" }
    );
  }
  return columns;
};

type WeekProjectionsDataRow = [Date, null, number, number, number];
export const getWeekProjectionData = (
  lastWeekDate: Date | string,
  numberOfWeeks: number,
  projectionsData: { [projectionKey: string]: number }
): WeekProjectionsDataRow => [
  new Date(dayjs(lastWeekDate).add(numberOfWeeks, "week").format()),
  null,
  projectionsData.projection,
  projectionsData.min,
  projectionsData.max,
];

// Adds additional rows to the chartDataArray with the projections data for each week.
export const addProjectionsData = (
  projectionsDataObject: EbolaProjections,
  chartDataArray: ([string, number, null] | WeekProjectionsDataRow)[]
) => {
  // Get the date of the last row in the chartDataArray.
  const lastWeekDate = chartDataArray[chartDataArray.length - 1][0];
  // Get the keys in the projectionsDataObject.
  const projectionsDataKeys = Object.keys(projectionsDataObject);
  // For each key in the projectionsDataKeys, push the weekly projections data to the chartDataArray.
  projectionsDataKeys.forEach((key, index) => {
    const numberOfWeeks = index + 1;
    chartDataArray.push(
      getWeekProjectionData(
        lastWeekDate,
        numberOfWeeks,
        projectionsDataObject[key]
      )
    );
  });
};

export const getAllCountriesEbolaChartData = (
  ebolaDataCombined: EbolaDataCombined,
  filters: Filters
) => {
  const chartData: any[] = [];
  let projectionsData: EbolaProjections = {
    oneWeek: null,
    twoWeeks: null,
    threeWeeks: null,
    fourWeeks: null,
  };
  // Add column headers to the chartData array.
  chartData.push(getChartColumns("Ebola", filters.dataType));
  // Add the data rows to the chartData array.
  ebolaDataCombined.forEach((row) => {
    // Only push the rows if the projection_from is within the filters.dateRange
    if (isDateWithinFiltersDateRange(row.projection_from, filters.dateRange)) {
      const dataRow = [new Date(row.projection_from), row.aggregated];
      if (filters.dataType === "projected cases") {
        // If projections are enabled, store the weekly projection data in the projectionsData object.
        // We also need to add a value of null to the end of the dataRow array.
        projectionsData.oneWeek = {
          min: parseFloat(row["ymin1.aggregated"]),
          projection: parseFloat(row["y1.aggregated"]),
          max: parseFloat(row["ymax1.aggregated"]),
        };
        projectionsData.twoWeeks = {
          min: parseFloat(row["ymin2.aggregated"]),
          projection: parseFloat(row["y2.aggregated"]),
          max: parseFloat(row["ymax2.aggregated"]),
        };
        projectionsData.threeWeeks = {
          min: parseFloat(row["ymin3.aggregated"]),
          projection: parseFloat(row["y3.aggregated"]),
          max: parseFloat(row["ymax3.aggregated"]),
        };
        projectionsData.fourWeeks = {
          min: parseFloat(row["ymin4.aggregated"]),
          projection: parseFloat(row["y4.aggregated"]),
          max: parseFloat(row["ymax4.aggregated"]),
        };
        dataRow.push(null, null, null);
      }
      chartData.push(dataRow);
    }
  });
  // If projections are enabled, add projections data to chartData array.
  if (filters.dataType === "projected cases") {
    addProjectionsData(projectionsData, chartData);
  }
  return chartData;
};

export const getSelectedCountryEbolaChartData = (
  ebolaData: CountriesEbolaData,
  filters: Filters
) => {
  const chartData: any[] = [];
  let projectionsData: EbolaProjections = {};
  // Add column headers to chartData array.
  chartData.push(getChartColumns("Ebola", filters.dataType));
  // Find the data for the selected country.
  const countryData = ebolaData[filters.country];
  // Get an array of date keys from the countryData object.
  const dateKeys = Object.keys(countryData);
  // For each dateKey in the dateKeys array, push a data row to the chartData array.
  dateKeys.forEach((dateKey) => {
    // Only push the rows if the dateKey is within the filters.dateRange
    if (isDateWithinFiltersDateRange(dateKey, filters.dateRange)) {
      const dataRow = [new Date(dateKey), countryData[dateKey].value];
      if (filters.dataType === "projected cases") {
        // If projections are enabled, store the weekly projection data in the projectionsData object.
        // We also need to add a value of null to the end of the dataRow array.
        projectionsData = countryData[dateKey].projections;
        dataRow.push(null, null, null);
      }
      chartData.push(dataRow);
    }
  });
  // If projections are enabled, add projections data to chartData array.
  if (filters.dataType === "projected cases") {
    addProjectionsData(projectionsData, chartData);
  }
  return chartData;
};

export const getEbolaDataForCharts = (
  ebolaData: CountriesEbolaData,
  ebolaDataCombined: EbolaDataCombined,
  filters: Filters
) => {
  const showEbolaDataCombined =
    ebolaDataCombined && ebolaDataCombined.length && filters.country === "All";
  const showCountryEbolaData =
    Object.keys(ebolaData).length && filters.country !== "All";
  // Here we are returning the ebola chart data for all countries.
  if (showEbolaDataCombined) {
    return getAllCountriesEbolaChartData(ebolaDataCombined, filters);
  }
  // Here we are returning the ebola chart data for a specific country.
  if (showCountryEbolaData) {
    return getSelectedCountryEbolaChartData(ebolaData, filters);
  }
};

export const getAllCountriesCovidChartData = (
  covidData: CountryCovidCounts[],
  filters: Filters
) => {
  const chartData = [];
  // Add column headers to chartData array.
  chartData.push(getChartColumns("COVID-19", filters.dataType));
  // Get an array of date keys from the countryData object of the first country data object.
  // Since all country data objects have data for the same dates, we are using the dates from the first country.
  const covidDataDateKeys = Object.keys(covidData[0].countryData);
  // Loop through all the 'covidDataDateKeys'.
  covidDataDateKeys.forEach((dateKey) => {
    // This is the caseCount for each dateKey.
    let caseCount = 0;
    // If the 'dateKey' is within the dates in the filters, execute this block.
    if (isDateWithinFiltersDateRange(dateKey, filters.dateRange)) {
      // Loop through each country data object in the covidData array.
      covidData.forEach((countryDataObject) => {
        // Get each country's daily totalCount for the day in the 'dateKey'.
        const countryDailyCaseCount =
          countryDataObject.countryData[dateKey].totalCount;
        // If the countryDailyCaseCount is an integer, add it to the caseCount counter.
        if (Number.isInteger(countryDailyCaseCount)) {
          caseCount += countryDailyCaseCount;
        }
      });
      const dataRow = [new Date(dateKey), caseCount];
      chartData.push(dataRow);
    }
  });
  return chartData;
};

export const getSelectedCountryCovidChartData = (
  covidData: CountryCovidCounts[],
  filters: Filters
) => {
  const chartData = [];
  // Add column headers to chartData array.
  chartData.push(getChartColumns("COVID-19", filters.dataType));
  // Find a data object for the country in the filters
  const countryDataObject = findCountryDataObject(
    covidData,
    filters.country
  ) as CountryCovidCounts;
  // If a countryDataObject is found, execute this block.
  if (countryDataObject) {
    // Get an array of all of the date keys in the countryDataObject.countryData.
    const covidDataDateKeys = Object.keys(countryDataObject.countryData);
    // Loop through all the 'covidDataDateKeys'.
    covidDataDateKeys.forEach((dateKey) => {
      // If the 'dateKey' is within the dates in the filters, push the data row to the chartData array.
      if (isDateWithinFiltersDateRange(dateKey, filters.dateRange)) {
        // If the country has a case/death totalCount for that day, get that value. Otherwise that value is 0.
        const countryDailyCaseCount = countryDataObject.countryData[dateKey]
          .totalCount
          ? countryDataObject.countryData[dateKey].totalCount
          : 0;
        const dataRow = [new Date(dateKey), countryDailyCaseCount];
        chartData.push(dataRow);
      }
    });
  }
  return chartData;
};

export const getCovidDataForCharts = (
  covidData: CountryCovidCounts[],
  filters: Filters
) => {
  const getAllCountriesCovidData =
    filters.country === "All" && Object.keys(covidData).length;
  const getSpecificCountryCovidData =
    filters.country !== "All" && Object.keys(covidData).length;
  if (getAllCountriesCovidData) {
    return getAllCountriesCovidChartData(covidData, filters);
  } else if (getSpecificCountryCovidData) {
    return getSelectedCountryCovidChartData(covidData, filters);
  }
};

export const getStartDateForCountryDeathProjections = (
  projectionsData: CountryCovidProjections[],
  selectedCountry: string
) => {
  // 1. Find country projections data object
  const countryProjectionsData = findCountryDataObject(
    projectionsData,
    selectedCountry
  ) as CountryCovidProjections;
  // 2. If a country data object is found, execute this block.
  if (countryProjectionsData) {
    const objectKeys = Object.keys(countryProjectionsData.countryData);
    // 3. Find the date key for the start of the last week.
    const startDateKey = objectKeys[objectKeys.length - 7];
    // 4. Format the date and return the date string.
    return dayjs(startDateKey).format("MMMM D, YYYY");
  }
};

export const getLast9WeeksProjectionDataKeys = (
  projectionsData: CovidProjectionsData
) => {
  const objectKeys = Object.keys(projectionsData);
  return objectKeys.slice(objectKeys.length - 63, objectKeys.length);
};

export const getCovidDeathProjectionsDataForChart = (
  projectionsData: CountryCovidProjections[],
  deathData: CountryCovidCounts[],
  selectedCountry: string
) => {
  const chartData: any[] = [
    [
      { type: "date", label: "Date" },
      { type: "number", label: "Observed Deaths" },
      { type: "string", role: "tooltip", p: { html: true } },
      { type: "number", label: "Median Projected Deaths" },
      { id: "interval-1", type: "number", role: "interval" },
      { id: "interval-0", type: "number", role: "interval" },
      { type: "string", role: "tooltip", p: { html: true } },
    ],
  ];
  // Find projections and deaths data objects for the selected country.
  const countryProjectionsDataObject = findCountryDataObject(
    projectionsData,
    selectedCountry
  ) as CountryCovidProjections;
  const countryDeathDataObject = findCountryDataObject(
    deathData,
    selectedCountry
  ) as CountryCovidCounts;
  // If both projections and deaths data objects for the selected country are found execute this block.
  if (countryProjectionsDataObject && countryDeathDataObject) {
    // Get the day keys for the last 9 weeks of the country's projections data.
    const last9WeeksProjectionDataKeys = getLast9WeeksProjectionDataKeys(
      countryProjectionsDataObject.countryData
    );
    last9WeeksProjectionDataKeys.forEach((dayKey, index) => {
      // Determines whether this row is showing projections data.
      const isProjectionsData = index > 55;
      const formattedDate = dayjs(dayKey).format("MMM D, YYYY");
      const dataRow: any[] = [new Date(formattedDate)];
      // If the country has a death count for the dayKey, the deathCount is the 'totalCount' for that dayKey. Otherwise deathCount is 0.
      const deathCount = countryDeathDataObject.countryData[dayKey]
        ? countryDeathDataObject.countryData[dayKey].totalCount
        : 0;
      if (isProjectionsData) {
        // If isProjectionsData is true, this block adds the "50" projections to the "Projected Deaths" column and the "2.5" and "97.5" projections to the interval columns.
        // Get the projections data for the dayKey.
        const dayProjectionsData =
          countryProjectionsDataObject.countryData[dayKey];
        // If there is projections data for that day, add the "2.5", "50", and "97.5" values from the projections data. Otherwise add 0.
        dataRow.push(
          null,
          null,
          dayProjectionsData ? dayProjectionsData["50"] : 0,
          dayProjectionsData ? dayProjectionsData["2.5"] : 0,
          dayProjectionsData ? dayProjectionsData["97.5"] : 0,
          "<div><h4>" +
            formattedDate +
            "</h4>Median Projected Deaths: <strong>" +
            Math.round(dayProjectionsData["50"]).toLocaleString() +
            " (" +
            Math.round(dayProjectionsData["2.5"]).toLocaleString() +
            "–" +
            Math.round(dayProjectionsData["97.5"]).toLocaleString() +
            ")</strong></div>"
        );
      } else {
        // Otherwise, add the deathCount to the "Observed Deaths" column and null values to the other 3 columns.
        dataRow.push(
          deathCount,
          "<div><h4>" +
            formattedDate +
            "</h4>Observed Deaths: <strong>" +
            deathCount.toLocaleString() +
            "</strong></div>",
          null,
          null,
          null,
          null
        );
      }
      //  Push the dataRow to the chartData array.
      chartData.push(dataRow);
    });
  }
  return chartData;
};

export const getTitleForReproductionNumberChart = (
  epidemicPhaseData: CountriesEpidemicPhaseData
) => {
  const firstCountryKey = Object.keys(epidemicPhaseData)[0];
  return firstCountryKey
    ? `Effective Reproduction Number data for the week starting on ${dayjs(
        epidemicPhaseData[firstCountryKey].forecastWeek
      ).format("MMMM D, YYYY")}`
    : "No Effective Reproduction Number data available";
};
