import styled from "styled-components";
import { media } from "../../assets/style-utils";

export const Body = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.75;
  margin: 2.4rem auto;
  ${media.mediumScreen`
    max-width: calc(8 / 12 * 100%);
  `}
`;
