import React from "react";
import LogoGroup from "../SharedComponents/LogoGroup";
import * as Styled from "../SharedStyledComponents";
import { Body } from "./styles";

const About = () => (
  <Styled.PageWrapper>
    <Styled.ContentWrapper>
      <Styled.Title>About</Styled.Title>
      <Styled.BodyLarge>
        MRIIDS aims to inform key health decision-makers at national and
        regional levels of the risks of an outbreak spreading in real-time, and
        aids government and non-governmental decision-makers as they prepare for
        the possible arrival of an infectious disease threat to their region.
      </Styled.BodyLarge>
    </Styled.ContentWrapper>
    <Styled.SectionWrapperLight>
      <Styled.ContentWrapper>
        <Styled.SectionTitle>How it works</Styled.SectionTitle>
        <Body>
          The International Society for Infectious Diseases (ISID), along with
          partners at ProMED, the Medical Research Council (MRC) Centre for
          Global Infectious Disease Analysis at Imperial College London, the
          University of Sussex (UK), HealthMap at Boston Children’s
          Hospital/Harvard Medical School, and healthsites.io, developed a
          user-friendly tool to both forecast case counts during infectious
          disease outbreaks and estimate the risk of infectious disease cases
          arriving from and departing to specific geographic areas. The project
          — Mapping the Risk of International Infectious Disease Spread — MRIIDS
          — assessed outbreak events reported on ProMED and HealthMap by
          combining multiple data streams into a single probabilistic framework.
        </Body>
        <Body>
          Disease outbreak information is assessed with automated intelligence
          capabilities that incorporates population density information to
          estimate the number of cases for specific outbreak events.
        </Body>
        <Body>
          Additionally, the algorithm provides a risk projection to describe
          where infectious diseases cases are most likely to arrive from and
          depart to for specific countries. The information generated by the
          platform is accessible free of charge and incorporated into a platform
          with extensive end-user testing.
        </Body>
        <Body>
          Developed based on data from the 2014-16 West Africa Ebola outbreak,
          the MRIIDS prototype was designed to be rapidly scalable by extending
          it to pathogens of significance to humans and animals on a global
          scale. The tool aims to inform key health decision-makers at national
          and regional levels of the risks of an outbreak spreading in
          real-time, and aids government and non-governmental decision-makers as
          they prepare for the possible arrival of an infectious disease threat
          to their region.
        </Body>
      </Styled.ContentWrapper>
    </Styled.SectionWrapperLight>
    <Styled.ContentWrapper>
      <Styled.SectionTitle>Public IT Resources</Styled.SectionTitle>
      <Body>
        The information technology resources developed for MRIIDS are hosted on
        a{" "}
        <a href="https://github.com/ISIDOrg/MRIIDS/wiki/Mapping-the-Risk-of-International-Infectious-Disease-Spread">
          public GitHub account
        </a>{" "}
        which is accessible to all interested parties. Thus, information
        developed for the program is not stored behind corporate walls and can
        be used by other organizations to further protect patient health.
      </Body>
      <Body>
        All COVID map visualizations and Ebola cases and projected cases map
        visualizations used{" "}
        <a href="https://www.react-simple-maps.io/">React Simple Maps</a>.
      </Body>
    </Styled.ContentWrapper>
    <Styled.SectionWrapperLight>
      <Styled.ContentWrapper>
        <Styled.SectionTitle>Funding</Styled.SectionTitle>
        <Body>
          Initial funding and development support for the MRIIDS program was
          provided by the United States Agency for International Development
          (USAID) Center for Accelerating Innovation and Impact through the
          "Combating Zika and Future Threats" grant challenge, by PandemicTech
          and by Wellcome Trust.
        </Body>
        <Body>
          In 2020, MRIIDS entered into the second phase of development with a
          $1.4-million grant from a philanthropic organization.
        </Body>
      </Styled.ContentWrapper>
    </Styled.SectionWrapperLight>
    <Styled.ContentWrapper>
      <Styled.SectionTitle>Phase 2</Styled.SectionTitle>
      <Body>
        MRIIDS 2.0 will build upon the success of the initial program and expand
        capabilities for infectious disease outbreak forecasting.
      </Body>
      <Body>
        The enhanced platform will incorporate new data streams such as personal
        mobility data, flight data, and new pathogens to improve the model’s
        applicability to new settings.
      </Body>
      <Body>
        In phase two, the existing research team will initially focus efforts on
        COVID-19 case predictions to assist the international response to the
        ongoing pandemic. Throughout this second phase of development, the
        updated platform will remain freely accessible to the public and all
        updates will be communicated on the program’s website.
      </Body>
    </Styled.ContentWrapper>
    <LogoGroup />
  </Styled.PageWrapper>
);

export default About;
