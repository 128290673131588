import * as d3 from "d3-fetch";
import * as types from "../constants/ActionTypes";
import {
  parseCountryEpidemicPhaseData,
  parseWeeklyDeathProjectionsData,
} from "../utils/covidDataHelpers";

export const fetchCovidCaseCounts = () => (dispatch) => {
  dispatch({ type: types.FETCH_COVID_CASE_COUNT_DATA_REQUEST });
  return d3
    .json(`${process.env.REACT_APP_COVID_DATA_JSON_BUCKET}cases.json`)
    .then((data) =>
      dispatch({
        type: types.FETCH_COVID_CASE_COUNT_DATA_SUCCESS,
        payload: data,
      })
    )
    .catch((error) => {
      dispatch({
        type: types.FETCH_COVID_CASE_COUNT_DATA_FAILURE,
        error,
      });
    });
};

export const fetchCovidDeathCounts = () => (dispatch) => {
  dispatch({ type: types.FETCH_COVID_DEATH_COUNT_DATA_REQUEST });
  return d3
    .json(`${process.env.REACT_APP_COVID_DATA_JSON_BUCKET}deaths.json`)
    .then((data) =>
      dispatch({
        type: types.FETCH_COVID_DEATH_COUNT_DATA_SUCCESS,
        payload: data,
      })
    )
    .catch((error) => {
      dispatch({
        type: types.FETCH_COVID_DEATH_COUNT_DATA_FAILURE,
        error,
      });
    });
};

export const fetchCovidDailyProjectionsData = () => (dispatch) => {
  dispatch({ type: types.FETCH_COVID_DAILY_PROJECTIONS_DATA_REQUEST });
  return d3
    .json(`${process.env.REACT_APP_BACKEND_URL}projections`)
    .then((data) =>
      dispatch({
        type: types.FETCH_COVID_DAILY_PROJECTIONS_DATA_SUCCESS,
        payload: data,
      })
    )
    .catch((error) => {
      dispatch({
        type: types.FETCH_COVID_DAILY_PROJECTIONS_DATA_FAILURE,
        error,
      });
    });
};

export const fetchCovidWeeklyProjectionsData = () => (dispatch) => {
  dispatch({ type: types.FETCH_COVID_WEEKLY_PROJECTIONS_DATA_REQUEST });
  return d3
    .csv(
      `${process.env.REACT_APP_DATA_UPLOADS_BUCKET}weekly_projected_deaths.csv`
    )
    .then((data) =>
      dispatch({
        type: types.FETCH_COVID_WEEKLY_PROJECTIONS_DATA_SUCCESS,
        payload: parseWeeklyDeathProjectionsData(data),
      })
    )
    .catch((error) =>
      dispatch({
        type: types.FETCH_COVID_WEEKLY_PROJECTIONS_DATA_FAILURE,
        error,
      })
    );
};

export const fetchCountryEpidemicPhaseData = () => (dispatch) => {
  dispatch({ type: types.FETCH_COUNTRY_EPIDEMIC_PHASE_DATA_REQUEST });
  return d3
    .csv(
      `${process.env.REACT_APP_DATA_UPLOADS_BUCKET}country_epidemic_phase.csv`
    )
    .then((data) =>
      dispatch({
        type: types.FETCH_COUNTRY_EPIDEMIC_PHASE_DATA_SUCCESS,
        payload: parseCountryEpidemicPhaseData(data),
      })
    )
    .catch((error) =>
      dispatch({
        type: types.FETCH_COUNTRY_EPIDEMIC_PHASE_DATA_FAILURE,
        error,
      })
    );
};
