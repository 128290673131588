import dayjs from "dayjs";
import {
  ebolaInitialDateRange,
  covidInitialDateRange,
} from "../constants/DateRanges";
import { DateRange } from "../common/types";

export const getNumberOfWeeksBetweenDates = (
  firstDate: Date | string,
  secondDate: Date | string
) => {
  const startDate = dayjs(firstDate);
  const endDate = dayjs(secondDate);
  // Returns the rounded number of weeks between the startDate and endDate.
  return Math.round(endDate.diff(startDate, "week", true));
};

export const isDateWithinFiltersDateRange = (
  weekDateString: string,
  dateRange: DateRange
) => {
  // Checks to see if the weekDateString is a date that falls within the dateRange from the filters.
  const dateValue = new Date(weekDateString);
  return dateValue > dateRange.from && dateValue < dateRange.to;
};

export const getLastDateValueWithinDateRange = (
  datesArray: string[] = [],
  dateRange: DateRange
) => {
  // 1. Get an array of dates that are within the dateRange.
  const validDatesArray: string[] = [];
  datesArray.forEach((date) => {
    if (isDateWithinFiltersDateRange(date, dateRange)) {
      validDatesArray.push(date);
    }
  });
  // 2. Return the last date in the validDatesArray.
  return validDatesArray[validDatesArray.length - 1];
};

//  Returns the initial date range depending on which outbreak is selected.
export const getOutbreakInitialDateRange = (outbreakSelected: string) =>
  outbreakSelected === "Ebola Outbreak"
    ? ebolaInitialDateRange
    : covidInitialDateRange;

export const getMinimumDateRangeDate = (outbreakSelected: string) =>
  outbreakSelected === "Ebola Outbreak"
    ? ebolaInitialDateRange.from
    : covidInitialDateRange.from;

export const getMaximumDateRangeDate = (outbreakSelected: string) =>
  outbreakSelected === "Ebola Outbreak"
    ? ebolaInitialDateRange.to
    : covidInitialDateRange.to;

// If the value is equal to the first element in the sliderRange, return the formatted "from" date from the dateRange.
// Else, return the formatted "to" date from the dateRange.
export const getSliderValueLabelContent = (
  sliderRange: number[],
  value: number,
  dateRange: DateRange
) =>
  value === sliderRange[0]
    ? dayjs(dateRange.from).format("MMM D, YYYY")
    : dayjs(dateRange.to).format("MMM D, YYYY");
