/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Chart from "react-apexcharts";
import { getChartData, getXAxisMaximumValue } from "./helpers";
import { options } from "./chartOptions";

const ReproductionNumberChart = ({
  countryEpidemicPhaseData,
  epidemicPhaseContinent,
}) => {
  const [chartOptions, updateChartOptions] = useState({
    ...options,
  });
  // Updates the chart series data when epidemicPhaseContinent is updated.
  useEffect(() => {
    const chartData = getChartData(
      countryEpidemicPhaseData,
      epidemicPhaseContinent
    );
    const xAxisMaximumValue = getXAxisMaximumValue(chartData);
    updateChartOptions({
      ...chartOptions,
      xaxis: {
        ...chartOptions.xaxis,
        max: xAxisMaximumValue,
        tickAmount: xAxisMaximumValue,
      },
      series: [
        {
          data: chartData,
        },
      ],
    });
  }, [epidemicPhaseContinent]);

  return (
    <Chart
      options={chartOptions}
      series={chartOptions.series}
      type="boxPlot"
      height="100%"
    />
  );
};

const mapStateToProps = (state) => ({
  countryEpidemicPhaseData: state.covid.countryEpidemicPhase.data,
  epidemicPhaseContinent: state.filters.epidemicPhaseContinent,
});

export default connect(mapStateToProps)(ReproductionNumberChart);
