import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  border: 4px solid #d5dee5;
  border-top: 4px solid #0a0a0a;
  border: 4px solid ${(props) => props.theme.secondaryColor};
  border-top: 4px solid ${(props) => props.theme.secondaryColorTintDark};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: ${spin} 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
