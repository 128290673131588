import {
  allCountries,
  ebolaOutbreakCountries,
} from "../../constants/Countries";
import { getCountriesCovidCounts } from "../../utils/covidDataHelpers";
import { getCountriesEbolaCaseCounts } from "../../utils/ebolaDataHelpers";
import {
  CountriesEbolaCounts,
  CountriesEbolaData,
  CountryCovidCounts,
  CovidCounts,
  CovidCountType,
  Filters,
} from "../../common/types";

type CountriesCovidCounts = {
  [countryName: string]: CovidCounts;
};

type CountryColorsDictionary = { [countryName: string]: string };

export const getEbolaScale = (
  caseCountDictionary: CountriesEbolaCounts
): number => {
  // Gets the scaleValue to be used by the snapshotMap and map legend.
  const maxCaseCountValue = Math.max(...Object.values(caseCountDictionary));
  let scaleValue;
  if (maxCaseCountValue < 20) {
    scaleValue = 20;
  } else if (maxCaseCountValue < 50) {
    scaleValue = 50;
  } else if (maxCaseCountValue < 500) {
    scaleValue = Math.ceil(maxCaseCountValue / 50) * 50;
  } else if (maxCaseCountValue < 1000) {
    scaleValue = Math.ceil(maxCaseCountValue / 100) * 100;
  } else if (maxCaseCountValue < 5000) {
    scaleValue = Math.ceil(maxCaseCountValue / 500) * 500;
  } else {
    scaleValue = Math.ceil(maxCaseCountValue / 1000) * 1000;
  }
  return scaleValue;
};

export const getHighestPer100kCountValue = (
  covidCountDictionary: CountriesCovidCounts
) => {
  const per100kCounts: number[] = [];
  const dataObjectKeys = Object.keys(covidCountDictionary);
  if (dataObjectKeys.length) {
    dataObjectKeys.forEach((countryKey) => {
      per100kCounts.push(covidCountDictionary[countryKey].per100kCount);
    });
    return Math.max(...per100kCounts);
  } else {
    return 0;
  }
};

export const getCovidScale = (covidCountDictionary: CountriesCovidCounts) => {
  // Gets the scaleValue to be used by the snapshotMap and map legend.
  const maxPer100kCountValue = getHighestPer100kCountValue(
    covidCountDictionary
  );
  let scaleValue;
  if (maxPer100kCountValue < 1000) {
    scaleValue = Math.ceil(maxPer100kCountValue / 100) * 100;
  } else if (maxPer100kCountValue < 5000) {
    scaleValue = Math.ceil(maxPer100kCountValue / 500) * 500;
  } else if (maxPer100kCountValue < 10000) {
    scaleValue = Math.ceil(maxPer100kCountValue / 1000) * 1000;
  } else if (maxPer100kCountValue < 20000) {
    scaleValue = Math.ceil(maxPer100kCountValue / 2000) * 2000;
  } else if (maxPer100kCountValue < 50000) {
    scaleValue = Math.ceil(maxPer100kCountValue / 5000) * 5000;
  } else if (maxPer100kCountValue < 100000) {
    scaleValue = Math.ceil(maxPer100kCountValue / 10000) * 10000;
  } else if (maxPer100kCountValue < 500000) {
    scaleValue = Math.ceil(maxPer100kCountValue / 50000) * 50000;
  } else if (maxPer100kCountValue < 1000000) {
    scaleValue = Math.ceil(maxPer100kCountValue / 100000) * 100000;
  } else if (maxPer100kCountValue < 5000000) {
    scaleValue = Math.ceil(maxPer100kCountValue / 500000) * 500000;
  } else if (maxPer100kCountValue < 10000000) {
    scaleValue = Math.ceil(maxPer100kCountValue / 1000000) * 1000000;
  } else {
    scaleValue = Math.ceil(maxPer100kCountValue / 2000000) * 2000000;
  }
  return scaleValue;
};

export const getSnapshotColor = (caseCount: number = 0) => {
  //  Gets the color values for the snapshot map and case count legend.
  //  This is for non-projection data.
  let color;
  if (caseCount === 0) {
    color = "#FDF1DD";
  } else if (caseCount > 0 && caseCount <= 0.1) {
    color = "#FBE7C6";
  } else if (caseCount > 0.1 && caseCount <= 0.2) {
    color = "#F8D1B6";
  } else if (caseCount > 0.2 && caseCount <= 0.3) {
    color = "#F5BCA7";
  } else if (caseCount > 0.3 && caseCount <= 0.4) {
    color = "#F1A697";
  } else if (caseCount > 0.4 && caseCount <= 0.5) {
    color = "#EE9187";
  } else if (caseCount > 0.5 && caseCount <= 0.6) {
    color = "#EB7C77";
  } else if (caseCount > 0.6 && caseCount <= 0.7) {
    color = "#E86769";
  } else if (caseCount > 0.7 && caseCount <= 0.8) {
    color = "#E55259";
  } else if (caseCount > 0.8) {
    color = "#E23D4A";
  }
  return color;
};

export const getSnapshotDeathsColor = (deathCount: number = 0) => {
  //  Gets the color values for the snapshot map and death count legend.
  //  This is for non-projection data.
  let color;
  if (deathCount === 0) {
    color = "#FDF1DD";
  } else if (deathCount > 0 && deathCount <= 0.1) {
    color = "#EFDCDF";
  } else if (deathCount > 0.1 && deathCount <= 0.2) {
    color = "#E1C7E0";
  } else if (deathCount > 0.2 && deathCount <= 0.3) {
    color = "#D4B4E1";
  } else if (deathCount > 0.3 && deathCount <= 0.4) {
    color = "#C49FE3";
  } else if (deathCount > 0.4 && deathCount <= 0.5) {
    color = "#B48CE1";
  } else if (deathCount > 0.5 && deathCount <= 0.6) {
    color = "#A178E3";
  } else if (deathCount > 0.6 && deathCount <= 0.7) {
    color = "#8E65E3";
  } else if (deathCount > 0.7 && deathCount <= 0.8) {
    color = "#7951E2";
  } else if (deathCount > 0.8) {
    color = "#613DE3";
  }
  return color;
};

export const getSnapshotProjectionsColor = (caseCount: number = 0) => {
  //  Gets the color values for the snapshot map and case count legend.
  //  This is for projection data.
  let color;
  if (caseCount === 0) {
    color = "#FDF1DD";
  } else if (caseCount > 0 && caseCount <= 0.1) {
    color = "#ffe8c8";
  } else if (caseCount > 0.1 && caseCount <= 0.2) {
    color = "#ffdfb4";
  } else if (caseCount > 0.2 && caseCount <= 0.3) {
    color = "#ffd5a0";
  } else if (caseCount > 0.3 && caseCount <= 0.4) {
    color = "#ffcc8b";
  } else if (caseCount > 0.4 && caseCount <= 0.5) {
    color = "#ffc276";
  } else if (caseCount > 0.5 && caseCount <= 0.6) {
    color = "#feb960";
  } else if (caseCount > 0.6 && caseCount <= 0.7) {
    color = "#fbb04a";
  } else if (caseCount > 0.7 && caseCount <= 0.8) {
    color = "#f8a731";
  } else if (caseCount > 0.8) {
    color = "#f59e0a";
  }
  return color;
};

// This gets a dictionary with key/value pairs of country/fillColor for each Ebola country.
export const getEbolaFillColorsDictionary = (
  caseCountDictionary: CountriesEbolaCounts,
  dataType: string
) => {
  const colorsDictionary: CountryColorsDictionary = {};
  // Get the scale using the countriesCaseCountDictionary.
  const scale = getEbolaScale(caseCountDictionary);
  ebolaOutbreakCountries.forEach((country) => {
    const percentage = caseCountDictionary[country] / scale;
    // If projections are enabled, get the fillColor value using the getSnapshotProjectionsColor function.
    // Otherwise get the fillColor value using the getSnapshotColor function.
    colorsDictionary[country] =
      dataType === "projected cases"
        ? getSnapshotProjectionsColor(percentage)
        : getSnapshotColor(percentage);
  });
  return colorsDictionary;
};

// This gets a dictionary with key/value pairs of country/fillColor for each country.
export const getCovidFillColorsDictionary = (
  covidCountDictionary: CountriesCovidCounts,
  dataType: string
) => {
  const colorsDictionary: CountryColorsDictionary = {};
  // Get the scale using the countriesDiseaseCountDictionary.
  const scale = getCovidScale(covidCountDictionary);
  allCountries.forEach((country) => {
    const countryCountsData = covidCountDictionary[country];
    // If data is found for this country, execute this block.
    if (countryCountsData) {
      const percentage = countryCountsData.per100kCount / scale;
      // If projections are enabled, get the fillColor value using the getSnapshotProjectionsColor function.
      // If viewing death counts, get the fillColor using the getSnapshotDeathsColor function.
      // If viewing case counts, get the fillColor using the getSnapshotColor function.
      if (dataType === "projected deaths") {
        colorsDictionary[country] = getSnapshotProjectionsColor(percentage);
      } else if (dataType === "cases") {
        colorsDictionary[country] = getSnapshotColor(percentage);
      } else if (dataType === "deaths") {
        colorsDictionary[country] = getSnapshotDeathsColor(percentage);
      }
    }
  });
  return colorsDictionary;
};

// Returns the fill color for a country on the SnapshotMap.
export const getCountryFillColor = (
  countryName: string,
  fillColorDictionary: CountryColorsDictionary
) => fillColorDictionary[countryName] ?? "#FCF1DD";

export const getCountryDiseaseCountDictionary = (
  ebolaData: CountriesEbolaData,
  covidCaseCountData: CountryCovidCounts[],
  covidDeathCountData: CountryCovidCounts[],
  filters: Filters
) => {
  // If the ebola outbreak is selected, return a dictionary of all ebola outbreak countries with the ebola case counts.
  if (filters.outbreak === "Ebola Outbreak") {
    return getCountriesEbolaCaseCounts(ebolaData, filters);
    // If the covid outbreak is selected and the data type is "cases", return a dictionary of all countries with the covid case counts.
  } else if (filters.outbreak === "COVID-19" && filters.dataType === "cases") {
    return getCountriesCovidCounts(covidCaseCountData, filters);
    // If the covid outbreak is selected and the data type is "deaths", return a dictionary of all countries with the covid death counts.
  } else if (filters.outbreak === "COVID-19" && filters.dataType === "deaths") {
    return getCountriesCovidCounts(covidDeathCountData, filters);
    //  If none of the above conditions are true, return an empty object for now.
    //  This is a placeholder while we decide how to display the covid "cases and deaths".
  } else {
    return {};
  }
};

export const getCountryDiseaseCountForPopup = (
  outbreakSelected: string,
  countryName: string,
  diseaseCountDictionary: CountriesEbolaCounts | CountriesCovidCounts,
  typeOfCount: CovidCountType = CovidCountType.TOTAL_COUNT
) => {
  if (outbreakSelected === "Ebola Outbreak") {
    return diseaseCountDictionary[countryName] as number;
  } else {
    const countryData = diseaseCountDictionary[countryName] as CovidCounts;
    // If we have data for the country, return the country's requested type of count. Otherwise return 0.
    return countryData ? countryData[typeOfCount] : 0;
  }
};

// Determines whether the country selected is valid for the selected outbreak.
export const isValidCountrySelection = (
  countryName: string,
  outbreakSelected: string
): boolean =>
  outbreakSelected === "Ebola Outbreak"
    ? ebolaOutbreakCountries.includes(countryName)
    : allCountries.includes(countryName);
