import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  font-size: 1.2rem;
  line-height: 2;
  padding: 0.9rem 1rem;
  margin: 1rem;
  width: 60%;
  text-align: center;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.borderColor};
  cursor: pointer;
  user-select: none;
  outline: none;
  &:focus {
    background: #fff;
    border: 2px solid #d24fc8;
  }
  &:hover {
    border: 1px solid #f1caf0;
    box-shadow: 0 0 0 1px ${(props) => props.theme.secondaryColorTint};
  }
  &:active {
    background-color: #fff;
    user-select: none;
  }
  &:disabled {
    cursor: auto;
    opacity: 0.65;
  }
`;
