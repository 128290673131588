export const caseCountOptions = {
  isStacked: true,
  bar: {
    groupWidth: "80%",
  },
  colors: ["#E23D4A", "#f59e0a"],
  intervals: { style: "area", color: "#f59e0a" },
  hAxis: {
    textStyle: {
      fontSize: 11,
    },
    gridlines: {
      color: "#ececec",
    },
    minorGridlines: {
      color: "#ececec",
    },
  },
  vAxis: {
    textStyle: {
      fontSize: 11,
    },
    gridlines: {
      color: "#ececec",
    },
    minorGridlines: {
      color: "#ececec",
    },
    baselineColor: "#ccc",
  },
  legend: {
    textStyle: {
      fontSize: 12,
    },
    position: "top",
    alignment: "center",
  },
  chartArea: {
    left: "80px",
    width: "calc(100% - 160px)",
  },
};

export const deathCountOptions = {
  isStacked: true,
  bar: {
    groupWidth: "80%",
  },
  colors: ["#643DE2", "#f8ae32"],
  hAxis: {
    textStyle: {
      fontSize: 11,
    },
    gridlines: {
      color: "#ececec",
    },
    minorGridlines: {
      color: "#ececec",
    },
  },
  vAxis: {
    textStyle: {
      fontSize: 11,
    },
    gridlines: {
      color: "#ececec",
    },
    minorGridlines: {
      color: "#ececec",
    },
    baselineColor: "#ccc",
  },
  legend: {
    textStyle: {
      fontSize: 12,
    },
    position: "top",
    alignment: "center",
  },
  chartArea: {
    left: "80px",
    width: "calc(100% - 160px)",
  },
};

export const covidDeathProjectionOptions = {
  colors: ["#643DE2", "#e33dce"],
  intervals: { style: "area", color: "#e33dce" },
  legend: {
    textStyle: {
      fontSize: 12,
    },
    position: "top",
    alignment: "center",
  },
  hAxis: {
    format: "MMM d",

    minorGridlines: { count: 0 },
  },

  seriesType: "scatter",
  series: { 1: { type: "line" } },

  tooltip: { isHtml: true },
};
