import Styled from "styled-components";

export const Logo = Styled.span`
  display: flex;
  justify-content: center;
  margin: 0 4.8rem 0 2.8rem;
  align-items: center;
  img {
    width: auto;
    height: 2.4rem;
  }
`;
