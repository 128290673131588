export const FETCH_EBOLA_DATA_REQUEST = "FETCH_EBOLA_DATA_REQUEST";
export const FETCH_EBOLA_DATA_SUCCESS = "FETCH_EBOLA_DATA_SUCCESS";
export const FETCH_EBOLA_DATA_FAILURE = "FETCH_EBOLA_DATA_FAILURE";

export const FETCH_EBOLA_DATA_COMBINED_REQUEST =
  "FETCH_EBOLA_DATA_COMBINED_REQUEST";
export const FETCH_EBOLA_DATA_COMBINED_SUCCESS =
  "FETCH_EBOLA_DATA_COMBINED_SUCCESS";
export const FETCH_EBOLA_DATA_COMBINED_FAILURE =
  "FETCH_EBOLA_DATA_COMBINED_FAILURE";

export const FETCH_RISK_DATA_REQUEST = "FETCH_RISK_DATA_REQUEST";
export const FETCH_RISK_DATA_SUCCESS = "FETCH_RISK_DATA_SUCCESS";
export const FETCH_RISK_DATA_FAILURE = "FETCH_RISK_DATA_FAILURE";

export const FETCH_COVID_CASE_COUNT_DATA_REQUEST =
  "FETCH_COVID_CASE_COUNT_DATA_REQUEST";
export const FETCH_COVID_CASE_COUNT_DATA_SUCCESS =
  "FETCH_COVID_CASE_COUNT_DATA_SUCCESS";
export const FETCH_COVID_CASE_COUNT_DATA_FAILURE =
  "FETCH_COVID_CASE_COUNT_DATA_FAILURE";

export const FETCH_COVID_DEATH_COUNT_DATA_REQUEST =
  "FETCH_COVID_DEATH_COUNT_DATA_REQUEST";
export const FETCH_COVID_DEATH_COUNT_DATA_SUCCESS =
  "FETCH_COVID_DEATH_COUNT_DATA_SUCCESS";
export const FETCH_COVID_DEATH_COUNT_DATA_FAILURE =
  "FETCH_COVID_DEATH_COUNT_DATA_FAILURE";

export const FETCH_COVID_DAILY_PROJECTIONS_DATA_REQUEST =
  "FETCH_COVID_DAILY_PROJECTIONS_DATA_REQUEST";
export const FETCH_COVID_DAILY_PROJECTIONS_DATA_SUCCESS =
  "FETCH_COVID_DAILY_PROJECTIONS_DATA_SUCCESS";
export const FETCH_COVID_DAILY_PROJECTIONS_DATA_FAILURE =
  "FETCH_COVID_DAILY_PROJECTIONS_DATA_FAILURE";

export const FETCH_COVID_WEEKLY_PROJECTIONS_DATA_REQUEST =
  "FETCH_COVID_WEEKLY_PROJECTIONS_DATA_REQUEST";
export const FETCH_COVID_WEEKLY_PROJECTIONS_DATA_SUCCESS =
  "FETCH_COVID_WEEKLY_PROJECTIONS_DATA_SUCCESS";
export const FETCH_COVID_WEEKLY_PROJECTIONS_DATA_FAILURE =
  "FETCH_COVID_WEEKLY_PROJECTIONS_DATA_FAILURE";

export const FETCH_COUNTRY_EPIDEMIC_PHASE_DATA_REQUEST =
  "FETCH_COUNTRY_EPIDEMIC_PHASE_DATA_REQUEST";
export const FETCH_COUNTRY_EPIDEMIC_PHASE_DATA_SUCCESS =
  "FETCH_COUNTRY_EPIDEMIC_PHASE_DATA_SUCCESS";
export const FETCH_COUNTRY_EPIDEMIC_PHASE_DATA_FAILURE =
  "FETCH_COUNTRY_EPIDEMIC_PHASE_DATA_FAILURE";

// These are used for updating the filters
export const CHANGE_COUNTRY = "CHANGE_COUNTRY";
export const CHANGE_OUTBREAK = "CHANGE_OUTBREAK";
export const CHANGE_DATE_RANGE = "CHANGE_DATE_RANGE";
export const CHANGE_DATA_TYPE = "CHANGE_DATA_TYPE";
export const CHANGE_DATE_SLIDER_RANGE = "CHANGE_DATE_SLIDER_RANGE";
export const OPEN_DATE_RANGE_POPOVER = "OPEN_DATE_RANGE_POPOVER";
export const CLOSE_DATE_RANGE_POPOVER = "CLOSE_DATE_RANGE_POPOVER";
export const SET_POPOVER_ANCHOR_ELEMENT = "SET_POPOVER_ANCHOR_ELEMENT";
export const CLEAR_POPOVER_ANCHOR_ELEMENT = "CLEAR_POPOVER_ANCHOR_ELEMENT";
export const CLOSE_DISCLAIMER_POPPER = "CLOSE_DISCLAIMER_POPPER";
export const SET_POPPER_ANCHOR_ELEMENT = "SET_POPPER_ANCHOR_ELEMENT";
export const CHANGE_FIRST_DATA_LOAD = "CHANGE_FIRST_DATA_LOAD";
export const CHANGE_EPIDEMIC_PHASE_CONTINENT =
  "CHANGE_EPIDEMIC_PHASE_CONTINENT";
