import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextField from "@material-ui/core/TextField";
import { StyledInputLabel } from "../../SharedStyledComponents";
import { getLocationOptions, isInvalidCountrySelection } from "./helpers";
import { changeCountryFilter } from "../../../actions/filters";
import * as Styled from "./styles";

const CountrySelect = ({
  outbreak,
  country,
  changeCountryFilter,
  dataType,
  classes,
}) => {
  const [locationOptions, updateLocationOptions] = useState([]);

  useEffect(() => {
    // If an invalid country is selected, reset the country selected to "All".
    if (isInvalidCountrySelection(country)) {
      changeCountryFilter("All");
    }
    // Update the locationOptions.
    const countryOptions = getLocationOptions(outbreak, dataType);
    updateLocationOptions(countryOptions);
  }, [dataType, country, outbreak, changeCountryFilter]);

  const onSelectCountry = (selectedValue) => {
    // If the selectedValue is not null, change the country in the filters Redux state.
    if (selectedValue) {
      changeCountryFilter(selectedValue);
    }
  };

  return (
    <>
      <StyledInputLabel>Location</StyledInputLabel>
      <Styled.StyledAutocomplete
        id="country-select"
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        onChange={(event, value) => onSelectCountry(value)}
        options={locationOptions}
        value={country}
        classes={classes}
        bordercolor="#FFFFFF"
        disableClearable
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  outbreak: state.filters.outbreak,
  country: state.filters.country,
  dataType: state.filters.dataType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeCountryFilter,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelect);
