import React from "react";
import MapLegendLevel from "../../SharedComponents/MapLegendLevel";
import {
  StyledBlockDropshadow,
  MapLegendWrapper,
} from "../../SharedStyledComponents";

const colors = ["#AB759B", "#AAD4CF", "#4DBDB3", "#EBD1C1", "#E7E7E7"];
const labels = [
  "Growing",
  "Stable/Growing Slowly",
  "Declining",
  "Unclear",
  "Unknown",
];

const MapLegend = () => (
  <MapLegendWrapper>
    <StyledBlockDropshadow>
      <h3>Current Epidemic Trend</h3>
      {colors.map((color, index) => (
        <MapLegendLevel
          key={`projections-legend-level-${index}`}
          color={color}
          value={labels[index]}
        />
      ))}
    </StyledBlockDropshadow>
  </MapLegendWrapper>
);

export default MapLegend;
