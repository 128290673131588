import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeDataType } from "../../../actions/filters";
import { StyledInputLabel } from "../../SharedStyledComponents";
import FormControl from "@material-ui/core/FormControl";
import * as Styled from "./styles";

const DataRadioButtons = ({ dataType, changeDataType, outbreakSelected }) => {
  const handleChange = (event) => {
    const dataTypeSelected = event.target.value;
    changeDataType(dataTypeSelected);
  };
  return (
    <Styled.DataRadioButtonsContainer>
      <FormControl component="fieldset">
        <StyledInputLabel>Data</StyledInputLabel>
        <Styled.StyledRadioGroup
          aria-label="data"
          name="data"
          value={dataType}
          onChange={handleChange}
        >
          <Styled.StyledFormControlLabel
            value="cases"
            control={<Styled.StyledRadio />}
            label="Cases"
          />
          {outbreakSelected === "COVID-19" ? (
            <>
              <Styled.StyledFormControlLabel
                value="deaths"
                control={<Styled.StyledRadio />}
                label="Deaths"
              />
              <Styled.StyledFormControlLabel
                value="projected deaths"
                control={<Styled.StyledRadio />}
                label="Projected Deaths"
              />
            </>
          ) : (
            <>
              <Styled.FormLabelIconWrapper>
                <Styled.StyledFormControlLabel
                  value="projected cases"
                  control={<Styled.StyledRadio />}
                  label="Projected Cases"
                />
              </Styled.FormLabelIconWrapper>
              <Styled.StyledFormControlLabel
                value="risk"
                control={<Styled.StyledRadio />}
                label="Risk"
              />
            </>
          )}
        </Styled.StyledRadioGroup>
      </FormControl>
    </Styled.DataRadioButtonsContainer>
  );
};

const mapStateToProps = (state) => ({
  dataType: state.filters.dataType,
  outbreakSelected: state.filters.outbreak,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changeDataType }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DataRadioButtons);
