import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const DisclaimerContainer = styled.div`
  padding: 3.2rem 1.2rem 3.2rem;
  font-size: 1.6rem;
  line-height: 1.5;
  border-radius: 3px;
  border-top: 1px solid ${(props) => props.theme.borderLightColor};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 3.2rem;
  p {
    font-size: 1.4rem;
    line-height: 1.42857142;
    margin: .8rem 0 0;
    padding: 0;
  }
`;

export const Link = styled(NavLink)`
  color: ${(props) => props.theme.textColor};
`;

