import React from "react";
import { connect } from "react-redux";
import { Chart } from "react-google-charts";
import {
  caseCountOptions,
  deathCountOptions,
} from "../../constants/GoogleChartOptions";
import {
  getChartTitle,
  getEbolaDataForCharts,
  getCovidDataForCharts,
} from "../../utils/chartDataHelpers";
import { ChartTitle } from "../SharedStyledComponents";
import * as Styled from "./styles";

const ChartComponent = ({
  filters,
  ebolaData,
  ebolaDataCombined,
  covidCaseCountsData,
  covidDeathCountsData,
  covidCaseCountsDataIsFetching,
  covidDeathCountsDataIsFetching,
}) => {
  // Determines whether we are showing the covid case or death counts in the chart.
  const covidData =
    filters.dataType === "deaths" ? covidDeathCountsData : covidCaseCountsData;

  // Get the chartData based on the outbreak selected in the filters
  const chartData =
    filters.outbreak === "Ebola Outbreak"
      ? getEbolaDataForCharts(ebolaData, ebolaDataCombined, filters)
      : getCovidDataForCharts(covidData, filters);

  const chartOptions =
    filters.dataType === "deaths" ? deathCountOptions : caseCountOptions;

  const titleText = getChartTitle(
    filters.outbreak,
    filters.dataType,
    filters.country
  );

  const chartComponent = (
    <>
      <Chart
        width="100%"
        height="100%"
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        data={chartData}
        options={chartOptions}
      />
      {filters.outbreak === "Ebola Outbreak" ? (
        <Styled.DataSource>
          Data Source: Curated{" "}
          <a href="https://github.com/sangeetabhatia03/mriids_manuscript/tree/master/data">
            ProMED and HealthMap records
          </a>{" "}
          on the human cases of Ebola Virus Disease in West Africa.
        </Styled.DataSource>
      ) : (
        <Styled.DataSource>
          Data Source: Reported daily counts of deaths and cases from the{" "}
          <a href="https://covid19.who.int">WHO website</a>.
        </Styled.DataSource>
      )}
    </>
  );

  const renderChartComponentForCovidOutbreak = () => {
    const covidDataFetched =
      !covidCaseCountsDataIsFetching && !covidDeathCountsDataIsFetching;
    // Only return something if the covid data has been fetched.
    if (covidDataFetched) {
      // If chartData is defined and has data, return the chartComponent.
      // Else, return a message saying we don't have data for the selected dateRange.
      return chartData && chartData.length > 1 ? (
        chartComponent
      ) : (
        <ChartTitle>
          We currently don't have data for the selected time period
        </ChartTitle>
      );
    }
  };

  return (
    <Styled.ChartContainer>
      <ChartTitle>{titleText}</ChartTitle>
      {filters.outbreak === "Ebola Outbreak"
        ? chartComponent
        : renderChartComponentForCovidOutbreak()}
    </Styled.ChartContainer>
  );
};

const mapStateToProps = (state) => ({
  filters: state.filters,
  ebolaData: state.ebola.ebolaData.data,
  ebolaDataCombined: state.ebola.ebolaDataCombined.data,
  covidCaseCountsData: state.covid.caseCounts.data,
  covidDeathCountsData: state.covid.deathCounts.data,
  covidCaseCountsDataIsFetching: state.covid.caseCounts.isFetching,
  covidDeathCountsDataIsFetching: state.covid.deathCounts.isFetching,
});

export default connect(mapStateToProps)(ChartComponent);
