const TeamMemberContent = {
  sangeeta: {
    name: "Sangeeta Bhatia, PhD",
    org: "Imperial College London",
    intro:
      "Dr Sangeeta Bhatia is a Research Associate at Imperial College London.",
    body: [
      "Her research interests include outbreak analytics and spatial modelling of the spread of outbreaks. She has played a key role in the Ebola response and COVID-19 response teams at Imperial College London.",
    ],
    image: "/images/sangeeta-bhatia.jpg",
  },
  anne: {
    name: "Anne Cori, PhD",
    org: "Imperial College London",
    intro:
      "Dr Anne Cori interests lie in developing statistical methods to understand the dynamics of epidemics and inform control policies, using a range of data.",
    body: [
      "She has worked on a variety of infections including Ebola, MERS, influenza, SARS, and HIV. She is particularly interested in developing methods that can be used in real time, for a broad range of pathogens, to quickly characterise their transmissibility, detect potential changes in transmission patterns over time and space, and predict possible outbreak trajectories under a range of control measures.",
    ],
    image: "/images/anne-cori.jpg",
  },
  jack: {
    name: "Jack Wardle",
    org: "Imperial College London",
    intro: "Jack is a PhD student at Imperial College London.",
    body: [
      "He is interested in ways to measure human movement and how these estimates of human mobility can be used to model the spread of infectious disease outbreaks.",
    ],
    image: "/images/jack-wardle.jpg",
  },
  pierre: {
    name: "Pierre Nouvellet, PhD",
    org: "University of Sussex",
    intro:
      "Pierre’s interests broadly lie in quantitative methods applied to infectious disease epidemiology.",
    body: [
      "A key aim is to develop quantitative modelling tools and techniques to assimilate, analyse and integrate data from the diverse sources. The goals are to improve disease surveillance, control, preparedness and intervention.",
    ],
    image: "/images/pierre-nouvellet.jpg",
  },
  emily: {
    name: "Emily Cohn, MPH",
    org: "HealthMap",
    intro:
      "Emily is the Director of Innovation Operations for HealthMap, the Computational Epidemiology Group and the Innovation and Digital Health Accelerator at Boston Children’s Hospital. ",
    body: [
      "Emily has a background in epidemiology and veterinary medicine. Emily oversees the group’s portfolio of projects and finance operations.",
    ],
    image: "/images/emily-cohn.jpg",
  },
  kara: {
    name: "Kara Sewalk, MPH",
    org: "HealthMap",
    intro:
      "Kara is a Program Manager with HealthMap, the Computational Epidemiology Group and the Innovation and Digital Health Accelerator at Boston Children’s Hospital.",
    body: [
      "Kara has a background in epidemiology and manages several grant-funded projects focused on innovative infectious surveillance.",
    ],
    image: "/images/kara-sewalk.jpg",
  },
  john: {
    name: "John Brownstein, PhD",
    org: "HealthMap",
    intro:
      "John is the Chief Innovation Officer of Boston Children’s Hospital, a Professor at Harvard Medical School, and directs the Computational Epidemiology Group and Innovation and Digital Health Accelerator at Boston Children’s Hospital.",
    body: [
      "He is an international leader in healthcare information technology, and in particular, the emerging fields of informatics and big data analytics. He was trained as an epidemiologist at Yale University and his work has pioneered ‘digital epidemiology’ - utilizing diverse digital data sources to understand population health. John is the co-founder of the HealthMap surveillance system.",
    ],
    image: "/images/john-brownstein.jpg",
  },
  fernando: {
    name: "Fernando Donaire",
    org: "HealthMap",
    intro:
      "Fernando is a full-stack Software Engineer at Boston Children’s Hospital Innovation and Digital Health Accelerator.",
    body: [
      "In addition to his work on MRIIDS, Fernando has worked on a number of HealthMap applications including VaccineFinder and Outbreaks Near Me.",
    ],
    image: "/images/fernando-donaire.jpg",
  },
  rachel: {
    name: "Rachel Chorney",
    org: "HealthMap",
    intro:
      "Rachel is a UX/UI Designer and Front-end Developer at Boston Children’s Hospital Innovation and Digital Health Accelerator.",
    body: [
      "Rachel has a background in Graphic Design and designs websites for many of the group’s initiatives.",
    ],
    image: "/images/rachel-chorney.jpg",
  },
  clark: {
    name: "Clark Freifeld, PhD",
    org: "HealthMap",
    intro:
      "Clark is an Assistant Teaching Professor of Computer Science at Northeastern University, and Affiliate Faculty at Boston Children's Hospital.",
    body: [
      "His research focuses on applications of computing and artificial intelligence to the improvement of population health. His projects include: HealthMap, a global disease surveillance platform; MedWatcher, a medical product safety monitoring system; and StreetRx, a crowdsourcing tool for understanding black market pharmaceutical transactions. He holds a bachelor’s in computer science and mathematics from Yale University, a master’s from the MIT Media Lab, and a PhD in biomedical engineering from Boston University.",
    ],
    image: "/images/clark-freifeld.jpg",
  },
  jared: {
    name: "Jared Hawkins, MMSc, PhD",
    org: "HealthMap",
    intro:
      "Jared is the Director of Technology and Product at Boston Children’s Hospital Innovation and Digital Health Accelerator and a faculty member in the Computational Health Informatics Program.",
    body: [
      "Jared works translationally bringing digital health projects from research into implementation and enterprise. His focus is on leveraging nontraditional digital data streams for health research and software development. Jared has a diverse background including biomedical research, informatics, computational modeling, and development.",
    ],
    image: "/images/jared-hawkins.jpg",
  },
  moritz: {
    name: "Moritz Kraemer, PhD",
    org: "HealthMap",
    intro:
      "Moritz's research addresses questions related to the spatial spread of infectious diseases.",
    body: [
      "Specifically he is concerned with developing methods that readily integrate epidemiological, spatial and genomic data and how novel insights can be best used to reduce the burden of infectious pathogens through effective intervention strategies.",
    ],
    image: "/images/moritz-kraemer.jpg",
  },
  britta: {
    name: "Britta Lassmann, MD",
    org: "ISID/ProMED",
    intro:
      "Britta is an Infectious Diseases physician with an interest in international knowledge transfer and the global exchange and sharing of best medical practices through digital technology.",
    body: [
      "She is drawn to data driven insights and the use of analytical methods to optimize population-based health outcomes. She has received project development support from the Bill & Melinda Gates Foundation, the Elsevier Foundation, IBM, the US Agency for International Development and many others.",
    ],
    image: "/images/britta-lassmann.jpg",
  },
  angel: {
    name: "Angel Desai, MD, MPH",
    org: "ProMED/UC Davis",
    intro:
      "Angel is an Infectious Diseases physician interested in leveraging informal surveillance methodologies for emerging diseases and outbreaks, particularly among displaced and other vulnerable populations.",
    body: [
      "Her other work includes global infection prevention and control measures in resource-limited settings with an interest in high consequence pathogens. She is currently receiving project support from PandemicTech.",
    ],
    image: "/images/angel-desai.jpg",
  },
  mark: {
    name: "Mark Herringer",
    org: "healthsites.io",
    intro:
      "I’m interested in open data and how health care location mapping and citizen participation can support humanitarian business models.",
    body: [
      "I run healthsites.io which is an initiative to build an open data commons of health facility data with OpenStreetMap. We’re building the tools and community to share and update health facility data. I’m an experienced digital producer and enjoy the Human-centered design approach.",
    ],
    image: "/images/mark-herringer.jpg",
  },
  sarah: {
    name: "Sarah Claire Loeb",
    org: "ISID/ProMED",
    intro:
      "Sarah Claire works at the International Society for Infectious Diseases as the Project Coordinator.",
    body: [
      "She has been contributing to the ongoing ProMED COVID-19 data curation process. She recently graduated from Tufts University with a major in Community Health.",
    ],
    image: "/images/sarah-claire-loeb.jpg",
  },
};
export default TeamMemberContent;
