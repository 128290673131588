import styled from "styled-components";

export const DateRangeComponentContainer = styled.div`
  padding: 2.4rem 0 4.8rem ${(props) => props.theme.sidebarWidth};
  display: flex;
  width: calc(100% - ${(props) => props.theme.sidebarWidth});
  max-width: 900px;
  margin: 0 auto;
  flex-direction: row;
  align-items: center;
  .MuiSlider-root {
    color: ${(props) => props.theme.secondaryColor};
  }
  .MuiSlider-thumb.Mui-focusVisible,
  .MuiSlider-thumb:hover {
    box-shadow: 0 0 0 6px ${(props) => props.theme.secondaryColorTintRgba};
  }
  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0 0 0 8px ${(props) => props.theme.secondaryColorTintRgba};
  }
`;

export const DateRangeSliderContainer = styled.div`
  position: relative;
  width: 80%;
  height: 2.8rem;
  flex-grow: 1;
`;

export const SliderDate = styled.p`
  font-size: 1.2rem;
  line-height: 2;
  font-weight: 600;
  color: ${(props) => props.theme.textColor};
  display: flex;
  flex-shrink: 0;
  margin: 0 1.6rem;
`;
