import React, { useState, memo, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import SnapshotMapLegend from "./SnapshotMapLegend";
import MapZoomButtons from "../SharedComponents/MapZoomButtons";
import SnapshotMapCountryPopup from "./SnapshotMapCountryPopup";
import {
  getEbolaFillColorsDictionary,
  getCovidFillColorsDictionary,
  getCountryFillColor,
} from "./helpers";
import {
  getCountryDiseaseCountDictionary,
  isValidCountrySelection,
} from "./helpers";
import { countriesCoordinates } from "../../constants/CountriesCoordinates";
import { covidDefaultCoordinates, ebolaDefaultCoordinates } from "./constants";
import { changeCountryFilter } from "../../actions/filters";
import { MapContainer, StyledTooltip } from "../SharedStyledComponents";
import { InfoBanner } from "../InfoBanner";
import { covidInfoBannerText } from "../../constants/CommonText";

const SnapshotMap = ({
  filters,
  changeCountryFilter,
  ebolaData,
  covidCaseCountData,
  covidDeathCountData,
}) => {
  const [mapCenter, setMapCenter] = useState(covidDefaultCoordinates.mapCenter);
  const [zoomLevel, setZoomLevel] = useState(covidDefaultCoordinates.zoomLevel);
  const [fillColorDictionary, setFillColorDictionary] = useState({});
  const [countryDiseaseCounts, updateCountryDiseaseCounts] = useState({});
  const [toolTipContent, setToolTipContent] = useState(null);

  // Resets the mapCenter and zoomLevel to the default values.
  const resetMapCenterAndZoom = (outbreakSelected) => {
    if (outbreakSelected === "Ebola Outbreak") {
      setZoomLevel(ebolaDefaultCoordinates.zoomLevel);
      setMapCenter(ebolaDefaultCoordinates.mapCenter);
    } else {
      setZoomLevel(covidDefaultCoordinates.zoomLevel);
      setMapCenter(covidDefaultCoordinates.mapCenter);
    }
  };
  // Getting the country disease counts for the selected outbreak when the filters are updated.
  useEffect(() => {
    const diseaseCountsDictionary = getCountryDiseaseCountDictionary(
      ebolaData,
      covidCaseCountData,
      covidDeathCountData,
      filters
    );
    updateCountryDiseaseCounts(diseaseCountsDictionary);
  }, [ebolaData, covidCaseCountData, covidDeathCountData, filters]);

  // Set the fillColorDictionary when the countryDiseaseCounts is updated or the outbreak or projections are changed.
  useEffect(() => {
    const colorDictionary =
      filters.outbreak === "Ebola Outbreak"
        ? getEbolaFillColorsDictionary(countryDiseaseCounts, filters.dataType)
        : getCovidFillColorsDictionary(countryDiseaseCounts, filters.dataType);
    setFillColorDictionary(colorDictionary);
  }, [countryDiseaseCounts, filters.outbreak, filters.dataType]);

  // Set the mapCenter and zoomLevel when a country is selected.
  // Also resets the zoomLevel when switching between outbreaks.
  useEffect(() => {
    const selectedCountryCoordinates = countriesCoordinates[filters.country];
    if (filters.country !== "All" && selectedCountryCoordinates) {
      setMapCenter([
        selectedCountryCoordinates.longitude,
        selectedCountryCoordinates.latitude,
      ]);
      setZoomLevel(selectedCountryCoordinates.zoomLevel);
    } else {
      resetMapCenterAndZoom(filters.outbreak);
    }
  }, [filters.country, filters.outbreak]);

  const changeZoomLevel = (newZoomLevel) => {
    // This prevents zooming in to a level higher than 35 and lower than 1.
    if (newZoomLevel <= 35 && newZoomLevel >= 1) {
      setZoomLevel(newZoomLevel);
    }
  };

  return (
    <MapContainer>
      {filters.outbreak === "COVID-19" && (
        <InfoBanner text={covidInfoBannerText} />
      )}
      <StyledTooltip>{toolTipContent}</StyledTooltip>
      <ComposableMap
        projection="geoMercator"
        height={700}
        style={{ backgroundColor: "#F1F5FB", width: "100%", height: "100%" }}
        stroke="#131D34"
        strokeWidth={0.02}
        data-tip=""
      >
        <ZoomableGroup zoom={zoomLevel} center={mapCenter} maxZoom={35}>
          <Geographies geography="mapData/world_50m.json">
            {({ geographies }) =>
              geographies.map((geo) => {
                //  Gets the fillColor for each country (geo).
                const fillColor = getCountryFillColor(
                  geo.properties.NAME,
                  fillColorDictionary
                );
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onClick={() => {
                      const validCountry = isValidCountrySelection(
                        geo.properties.NAME,
                        filters.outbreak
                      );
                      if (validCountry) {
                        changeCountryFilter(geo.properties.NAME);
                      }
                    }}
                    onMouseEnter={() => {
                      setToolTipContent(
                        <SnapshotMapCountryPopup
                          countryName={geo.properties.NAME}
                          countriesDiseaseCountsDictionary={
                            countryDiseaseCounts
                          }
                        />
                      );
                    }}
                    onMouseLeave={() => setToolTipContent("")}
                    style={{
                      default: {
                        fill: fillColor,
                        opacity: 1,
                        transition: "all .5s ease",
                      },
                      hover: {
                        fill: fillColor,
                        opacity: 0.8,
                        transition: "all .5s ease",
                      },
                      pressed: {
                        fill: fillColor,
                        opacity: 0.8,
                        transition: "all .5s ease",
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <SnapshotMapLegend countryDiseaseCounts={countryDiseaseCounts} />
      <MapZoomButtons
        zoomLevel={zoomLevel}
        changeZoomFunction={changeZoomLevel}
        maxZoom={35}
        minZoom={1}
      />
    </MapContainer>
  );
};

const mapStateToProps = (state) => ({
  filters: state.filters,
  ebolaData: state.ebola.ebolaData.data,
  covidCaseCountData: state.covid.caseCounts.data,
  covidDeathCountData: state.covid.deathCounts.data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeCountryFilter,
    },
    dispatch
  );

// Wrapped SnapshotMap component in memo() as recommended here: https://www.react-simple-maps.io/examples/map-chart-with-tooltip/
export default connect(mapStateToProps, mapDispatchToProps)(memo(SnapshotMap));
