import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Chart } from "react-google-charts";
import {
  getCovidDeathProjectionsDataForChart,
  getStartDateForCountryDeathProjections,
  getTitleForReproductionNumberChart,
} from "../../utils/chartDataHelpers";
import { covidDeathProjectionOptions } from "../../constants/GoogleChartOptions";
import ReproductionNumberChart from "../ReproductionNumberChart";
import ContinentButtons from "../ReproductionNumberChart/ContinentButtons";
import { ChartTitle } from "../SharedStyledComponents";
import * as Styled from "./styles";

const CovidProjectionsChart = ({
  selectedCountry,
  projectedDailyDeathsData,
  projectedDailyDeathsIsFetching,
  deathsData,
  countryEpidemicPhaseData,
}) => {
  const chartData = getCovidDeathProjectionsDataForChart(
    projectedDailyDeathsData,
    deathsData,
    selectedCountry
  );
  // This determines whether the selected country has any projections data.
  const countryHasProjectionsData = chartData.length > 1;

  // This is the date that will be displayed in the chart title.
  const projectionsStartDate = getStartDateForCountryDeathProjections(
    projectedDailyDeathsData,
    selectedCountry
  );
  return (
    <>
      {selectedCountry === "All" ? (
        <Styled.ReproductionChartContainer>
          <ChartTitle>
            {getTitleForReproductionNumberChart(countryEpidemicPhaseData)}
          </ChartTitle>
          <ContinentButtons />
          <ReproductionNumberChart />
        </Styled.ReproductionChartContainer>
      ) : (
        <Styled.CovidProjectionsContainer>
          <ChartTitle>
            {countryHasProjectionsData
              ? `Projected Deaths for ${selectedCountry} for the week starting on ${projectionsStartDate}`
              : `We currently don't have death projections data for ${selectedCountry}`}
          </ChartTitle>
          {!countryHasProjectionsData && (
            <Styled.ChartText>
              See{" "}
              <NavLink exact to="/methods">
                Methods{" "}
              </NavLink>
              for more information.
            </Styled.ChartText>
          )}
          {countryHasProjectionsData && !projectedDailyDeathsIsFetching && (
            <Styled.ChartContainer>
              <Styled.LegendText>
                The shaded region represents the 95% credible interval. See the{" "}
                <a href="https://mrc-ide.github.io/covid19-short-term-forecasts/#model-1">
                  methods underlying the projections and estimation of current
                  trend
                </a>
                .
              </Styled.LegendText>
              <Chart
                width="100%"
                height="100%"
                chartType="ComboChart"
                loader={<div>Loading Chart</div>}
                data={chartData}
                options={covidDeathProjectionOptions}
              />
            </Styled.ChartContainer>
          )}
        </Styled.CovidProjectionsContainer>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedCountry: state.filters.country,
  projectedDailyDeathsData: state.covid.projections.daily.data,
  projectedDailyDeathsIsFetching: state.covid.projections.daily.isFetching,
  deathsData: state.covid.deathCounts.data,
  countryEpidemicPhaseData: state.covid.countryEpidemicPhase.data,
});

export default connect(mapStateToProps)(CovidProjectionsChart);
