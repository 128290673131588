import { isDateWithinFiltersDateRange } from "./dateHelpers";
import { roundToTwoDecimalPlaces } from "./commonHelpers";
import { allCountries } from "../constants/Countries";
import {
  DateRange,
  CovidCountsData,
  CovidCounts,
  CovidCountType,
  CountryCovidCounts,
  CountryCovidProjections,
  CountriesWeeklyDeathProjections,
  CountriesEpidemicPhaseData,
  Filters,
} from "../common/types";
import {
  WeeklyDeathProjectionsCsvDataRow,
  EpidemicPhaseCsvDataRow,
} from "./types";

export const getCountryCountInDateRange = (
  countryCovidData: CovidCountsData,
  dateRange: DateRange,
  typeOfCount: CovidCountType = CovidCountType.TOTAL_COUNT
): number => {
  let count = 0;
  if (countryCovidData) {
    Object.keys(countryCovidData).forEach((weekKey) => {
      if (isDateWithinFiltersDateRange(weekKey, dateRange)) {
        count += countryCovidData[weekKey][typeOfCount];
      }
    });
  }
  return count;
};

export const getAllCountriesCount = (
  covidData: CountryCovidCounts[] = [],
  dateRange: DateRange
): number => {
  let count = 0;
  covidData.forEach((countryObject) => {
    const countryCount = getCountryCountInDateRange(
      countryObject.countryData,
      dateRange,
      CovidCountType.TOTAL_COUNT
    );
    // This check prevents NaN from being added to the count.
    if (Number.isInteger(countryCount)) {
      count += countryCount;
    }
  });
  return count;
};

// This gets the case/death count for the Sidebar
export const getCovidCount = (
  covidData: CountryCovidCounts[] = [],
  filters: Filters
): number => {
  let count = 0;
  if (filters.country === "All") {
    // If "All" countries are selected, get the case/death count for all countries within the dateRange.
    count = getAllCountriesCount(covidData, filters.dateRange);
  } else {
    // Finds the data object for the country selected in filters.country.
    const selectedCountryDataObject = covidData.find(
      (dataObject) => dataObject.countryName === filters.country
    );
    // If data for the country is found, get the case/death count for the country and set it to the count variable.
    if (selectedCountryDataObject) {
      count = getCountryCountInDateRange(
        selectedCountryDataObject.countryData,
        filters.dateRange,
        CovidCountType.TOTAL_COUNT
      );
    }
  }
  // Only returns the count value if it is an integer. This ensures the count returned is not NaN.
  return Number.isInteger(count) ? count : 0;
};

// Find data object for the specified country.
export const findCountryDataObject = (
  covidData: (CountryCovidCounts | CountryCovidProjections)[],
  countryName: string
) => covidData.find((dataObject) => dataObject.countryName === countryName);

type CountriesCounts = { [countryName: string]: CovidCounts };
// This gets the case/death counts for all countries for the Snapshot map.
export const getCountriesCovidCounts = (
  covidData: CountryCovidCounts[] = [],
  filters: Filters
): CountriesCounts => {
  let countriesCounts: CountriesCounts = {};
  allCountries.forEach((country) => {
    let countryCounts = {
      totalCount: 0,
      per100kCount: 0,
    };
    // 1. Find the data object for the country.
    const countryDataObject = findCountryDataObject(covidData, country);
    // 2. If a countryDataObject is found, execute this block.
    if (countryDataObject) {
      // Get the country's totalCount within the date range in the filters.
      const countryTotalCount = getCountryCountInDateRange(
        countryDataObject.countryData as CovidCountsData,
        filters.dateRange,
        CovidCountType.TOTAL_COUNT
      );
      // If the country has a countryTotalCount, assign that to countryCounts.totalCount. Otherwise countryCounts.totalCount is 0.
      countryCounts.totalCount = countryTotalCount ? countryTotalCount : 0;
      // Get the country's per100kCount within the date range in the filters.
      const countryPer100kCount = getCountryCountInDateRange(
        countryDataObject.countryData as CovidCountsData,
        filters.dateRange,
        CovidCountType.PER_100K_COUNT
      );
      // If the country has a countryPer100kCount, assign that to countryCounts.per100kCount. Otherwise countryCounts.per100kCount is 0.
      countryCounts.per100kCount = countryPer100kCount
        ? countryPer100kCount
        : 0;
    }
    // 3. Add the countryCounts to the countriesCounts object.
    countriesCounts[country] = countryCounts;
  });
  return countriesCounts;
};

export const parseCountryEpidemicPhaseData = (
  csvData: EpidemicPhaseCsvDataRow[] = []
): CountriesEpidemicPhaseData => {
  const parsedData: CountriesEpidemicPhaseData = {};
  csvData.forEach((row) => {
    const cleanedCountryName = row.country.replace(/_/g, " ");
    if (row["si"] === "si_2" && !parsedData[cleanedCountryName]) {
      parsedData[cleanedCountryName] = {
        phase: row.phase,
        continent: row.continent,
        "2.5%": roundToTwoDecimalPlaces(parseFloat(row["2.5%"])),
        "25%": roundToTwoDecimalPlaces(parseFloat(row["25%"])),
        "50%": roundToTwoDecimalPlaces(parseFloat(row["50%"])),
        "75%": roundToTwoDecimalPlaces(parseFloat(row["75%"])),
        "97.5%": roundToTwoDecimalPlaces(parseFloat(row["97.5%"])),
        forecastWeek: row["week_of_forecast"],
      };
    }
  });
  return parsedData;
};

export const parseWeeklyDeathProjectionsData = (
  csvData: WeeklyDeathProjectionsCsvDataRow[]
) => {
  const parsedData: CountriesWeeklyDeathProjections = {};
  csvData.forEach((row) => {
    const cleanedCountryName = row.country.replace(/_/g, " ");
    if (row["si"] === "si_2" && !parsedData[cleanedCountryName]) {
      parsedData[cleanedCountryName] = {
        projectionsDate: row["proj"],
        2.5: roundToTwoDecimalPlaces(parseFloat(row["2.5%"])),
        50: roundToTwoDecimalPlaces(parseFloat(row["50%"])),
        97.5: roundToTwoDecimalPlaces(parseFloat(row["97.5%"])),
      };
    }
  });
  return parsedData;
};
