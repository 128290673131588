import styled from "styled-components";

export const CovidProjectionsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: ${(props) => props.theme.sidebarWidth};
  height: calc(100vh - 7.6rem);
  margin: 0 2rem;
  a {
    color: ${(props) => props.theme.textColor};
    &:focus,
    &:hover {
      color: ${(props) => props.theme.secondaryColor};
    }
  }
`;

export const ChartContainer = styled.div`
  height: calc((100vh / 2) - 7.6rem);
  min-height: 32rem;
  width: 100%;
`;

export const ReproductionChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: calc((100vh / 2) - 7.6rem);
  padding-left: ${(props) => props.theme.sidebarWidth};
  min-height: 32rem;
  margin-left: 20rem;
  width: 60%;
`;

export const LegendText = styled.p`
  font-size: 1.4rem;
  line-height: 2;
  margin: 1rem 0 0;
  text-align: center;
`;

export const ChartText = styled.p`
  font-size: 1.6rem;
  line-height: 1.5;
  margin: 1rem 0 0;
  text-align: center;
`;
