export const getLegendTitle = (outbreakSelected: string, dataType: string) => {
  if (outbreakSelected === "Ebola Outbreak") {
    return dataType.includes("projected")
      ? "Total outbreak projections"
      : "Case counts";
  } else {
    const covidOutbreakDataType = dataType.includes("deaths")
      ? "Cumulative Deaths"
      : "Cumulative Cases";
    return `${covidOutbreakDataType} per 100k`;
  }
};
