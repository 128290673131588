import React from "react";
import { connect } from "react-redux";
import {
  getReproductionNumber,
  getPhaseLabelText,
  getObservedDeathsLastWeek,
  getCurrentWeekPredictedDeaths,
} from "./helpers";
import { getFillColor } from "../helpers";
import * as Styled from "./styles";

const Popup = ({
  countryName,
  countriesEpidemicPhaseData,
  covidDeathCounts,
  covidWeeklyDeathProjections,
}) => {
  const countryPhaseData = countriesEpidemicPhaseData[countryName];
  const countryWeeklyProjections = covidWeeklyDeathProjections[countryName];

  return (
    <Styled.Container>
      <Styled.PhaseLabel fillColor={getFillColor(countryPhaseData?.phase)}>
        {getPhaseLabelText(countryPhaseData?.phase)}
      </Styled.PhaseLabel>
      <Styled.TitleContainer>{countryName}</Styled.TitleContainer>
      <Styled.Section>
        <Styled.Label>Current Week Projected Deaths</Styled.Label>
        <p>{getCurrentWeekPredictedDeaths(countryWeeklyProjections)}</p>
      </Styled.Section>
      <Styled.Section>
        <Styled.Label>Previous Week Observed Deaths</Styled.Label>
        <p>
          {countryPhaseData
            ? getObservedDeathsLastWeek(
                countryName,
                countryPhaseData.forecastWeek,
                covidDeathCounts
              )
            : "N/A"}
        </p>
      </Styled.Section>
      <Styled.Section>
        <Styled.Label>Current Week Reproduction Number</Styled.Label>
        <p>{getReproductionNumber(countryPhaseData)}</p>
      </Styled.Section>
    </Styled.Container>
  );
};

const mapStateToProps = (state) => ({
  countriesEpidemicPhaseData: state.covid.countryEpidemicPhase.data,
  covidDeathCounts: state.covid.deathCounts.data,
  covidWeeklyDeathProjections: state.covid.projections.weekly.data,
});

export default connect(mapStateToProps)(Popup);
