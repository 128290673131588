import styled from "styled-components";

export const Container = styled.div`
  width: 25rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  text-align: center;
  font-weight: 700;
  font-size: 1.4rem;
  margin: 0.8rem 0 1.6rem;
  line-height: 1.4285714;
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0.8rem 0;
  font-weight: 600;

  p {
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.4285714;
  }
`;

export const Label = styled.p`
  color: ${(props) => props.theme.textLightColor};
  font-weight: 700;
  letter-spacing: 0.05rem;
  font-size: 1.2rem !important;
  line-height: 2;
`;

export const PhaseLabel = styled.p`
  width: 70%;
  text-align: center;
  background-color: ${(props) => props.fillColor};
  border-radius: 3px;
  padding: 2px 8px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  font-size: 1.2rem !important;
  line-height: 2;
`;
