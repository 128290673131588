/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import dayjs from "dayjs";
import { changeDateRange, changeFirstDataLoad } from "../../actions/filters";
import { changeDateSliderRange } from "../../actions/ui";
import { Slider } from "@material-ui/core";
import {
  getNumberOfWeeksBetweenDates,
  getOutbreakInitialDateRange,
  getSliderValueLabelContent,
} from "../../utils/dateHelpers";
import * as Styled from "./styles";

const DateRange = ({
  filters,
  sliderRange,

  changeDateRange,
  changeDateSliderRange,
  changeFirstDataLoad,
}) => {
  const initialDateRange = getOutbreakInitialDateRange(filters.outbreak);
  const numberOfWeeksInInitialDateRange = getNumberOfWeeksBetweenDates(
    initialDateRange.from,
    initialDateRange.to
  );
  const [maxSliderValue, updateMaxSliderValue] = useState(
    numberOfWeeksInInitialDateRange
  );
  // This useEffect updates the dateRange and sliderRange when the filters.outbreak prop changes.
  useEffect(() => {
    //Do the following only on initial load, on subsequent loads value from Redux store will be used
    if (filters.firstDataLoad) {
      // Resets the dateRange filter.
      changeDateRange([initialDateRange.from, initialDateRange.to]);
      // Resets the values for the sliderRange.
      changeDateSliderRange([0, numberOfWeeksInInitialDateRange]);
      // Update the maxSliderValue.
      updateMaxSliderValue(numberOfWeeksInInitialDateRange);
      //Reset First Data Load to false
      changeFirstDataLoad(false);
    }
  }, [
    filters.outbreak,
    changeDateRange,
    changeDateSliderRange,
    initialDateRange,
    filters.firstDataLoad,
    changeFirstDataLoad,
  ]);

  const handleRangeChange = (event, newRangeArray) => {
    // Only execute this block if the newRangeArray is different from the sliderRange.
    if (sliderRange !== newRangeArray) {
      // Get the newFromDate by adding the number of weeks in the first value of the newRangeArray to the date in initialDateRange.from.
      const newFromDate = new Date(
        dayjs(initialDateRange.from).add(newRangeArray[0], "week")
      );
      // Get the newToDate by adding the number of weeks in the second value of the newRangeArray to the date in initialDateRange.from.
      const newToDate = new Date(
        dayjs(initialDateRange.from).add(newRangeArray[1], "week")
      );
      // Changes the dateRange filter in the Redux state.
      changeDateRange([newFromDate, newToDate]);
      // Sets the new sliderRange.
      changeDateSliderRange(newRangeArray);
    }
  };

  return (
    <Styled.DateRangeComponentContainer>
      <Styled.SliderDate>
        {dayjs(initialDateRange.from).format("MMM D, YYYY")}
      </Styled.SliderDate>
      <Styled.DateRangeSliderContainer>
        <Slider
          value={sliderRange}
          min={0}
          max={maxSliderValue}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => (
            <>
              {getSliderValueLabelContent(
                sliderRange,
                value,
                filters.dateRange
              )}
            </>
          )}
          onChange={handleRangeChange}
        />
      </Styled.DateRangeSliderContainer>
      <Styled.SliderDate>
        {dayjs(initialDateRange.to).format("MMM D, YYYY")}
      </Styled.SliderDate>
    </Styled.DateRangeComponentContainer>
  );
};

const mapStateToProps = (state) => ({
  filters: state.filters,
  sliderRange: state.ui.dateSliderRange,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeDateRange,
      changeDateSliderRange,
      changeFirstDataLoad,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DateRange);
