import React from "react";
import TeamMember from "./TeamMember";
import LogoGroup from "../SharedComponents/LogoGroup";
import * as Styled from "../SharedStyledComponents";
import {
  FirstPageContainer,
  SecondPageContainer,
  TeamMemberWrapper,
} from "./styles";

const Team = () => (
  <>
    <FirstPageContainer>
      <Styled.ContentWrapper>
        <Styled.Title>Team</Styled.Title>
        <Styled.BodyLarge>
          The MRIIDS project is a collaboration between team members from The
          International Society for Infectious Diseases (ISID) and its Program
          on Monitoring Emerging Diseases (ProMED), the Medical Research Council
          (MRC) Centre for Global Infectious Disease Analysis at Imperial
          College London, the University of Sussex (UK), HealthMap at Boston
          Children’s Hospital/Harvard Medical School, and healthsites.io.
        </Styled.BodyLarge>
      </Styled.ContentWrapper>
    </FirstPageContainer>
    <SecondPageContainer>
      <Styled.ContentWrapper>
        <TeamMemberWrapper>
          <TeamMember content="sangeeta" />
          <TeamMember content="anne" />
          <TeamMember content="jack" />
          <TeamMember content="pierre" />
          <TeamMember content="emily" />
          <TeamMember content="kara" />
          <TeamMember content="john" />
          <TeamMember content="clark" />
          <TeamMember content="jared" />
          <TeamMember content="fernando" />
          <TeamMember content="rachel" />
          <TeamMember content="moritz" />
          <TeamMember content="britta" />
          <TeamMember content="sarah" />
          <TeamMember content="angel" />
          <TeamMember content="mark" />
        </TeamMemberWrapper>
      </Styled.ContentWrapper>
    </SecondPageContainer>
    <LogoGroup />
  </>
);

export default Team;
