import { CountriesEpidemicPhaseData, ChartData } from "./types";

export const getChartData = (
  countriesEpidemicPhaseData: CountriesEpidemicPhaseData = {},
  continent: string = "Europe"
) => {
  const dataForChart: ChartData = [];
  Object.keys(countriesEpidemicPhaseData).forEach((country) => {
    const countryData = countriesEpidemicPhaseData[country];
    if (countryData.continent === continent) {
      dataForChart.push({
        x: country,
        y: [
          countryData["2.5%"],
          countryData["25%"],
          countryData["50%"],
          countryData["75%"],
          countryData["97.5%"],
        ],
      });
    }
  });
  // Sort the data objects in dataForChart based on the y[2] values.
  dataForChart.sort((a, b) => a.y[2] - b.y[2]);
  return dataForChart;
};

export const getXAxisMaximumValue = (chartData: ChartData) => {
  const values: number[] = [];
  chartData.forEach((countryData) => {
    values.push(countryData.y[4]);
  });
  return values.length ? Math.ceil(Math.max(...values)) : 1;
};
