import {
  ebolaOutbreakCountries,
  allCountries,
} from "../../../constants/Countries";

export const getLocationOptions = (outbreakSelected: string): string[] =>
  outbreakSelected === "Ebola Outbreak"
    ? ["All", ...ebolaOutbreakCountries]
    : ["All", ...allCountries];

export const isInvalidCountrySelection = (countrySelected: string) =>
  !allCountries.includes(countrySelected);
