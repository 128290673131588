import React from "react";

const Popup = ({ data }) => {
  return (
    <div>
      <span>
        <strong>{data.x}</strong>
      </span>
      <br />
      <span>
        <strong>Minimum: </strong>
        {data.y[0]}
      </span>
      <br />
      <span>
        <strong>25%: </strong>
        {data.y[1]}
      </span>
      <br />
      <span>
        <strong>50%: </strong>
        {data.y[2]}
      </span>
      <br />
      <span>
        <strong>75%: </strong>
        {data.y[3]}
      </span>
      <br />
      <span>
        <strong>Maximum: </strong>
        {data.y[4]}
      </span>
    </div>
  );
};

export default Popup;
